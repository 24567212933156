import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from '../styles/StyleConf'
import { withLocale } from '../context/l10nContext'
import { TranslateString as t } from '../utils/DictionaryUtils'

const Error = ({ locale, type, format, text }) => {
    let output
    let string

    const Container = styled.p`
        color: ${Colors.errorRed};
    `

    switch (type) {
        case 'timeout':
            string = t(locale, 'ui__error--connection-timeout')
            break
        case 'connection':
            string = t(locale, 'ui__error--connection-missing')
            break
        default:
            string = text
    }

    switch (format) {
        default:
            output = <Container>{string}</Container>
    }

    return output
}

Error.propTypes = {
    locale: PropTypes.string.isRequired,
    type: PropTypes.string,
    format: PropTypes.string,
    text: PropTypes.string,
}

export default withLocale(Error)
