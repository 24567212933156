export default {
    fi: {
        'ui__navi--dashboard': 'Työpöytä',
        'ui__navi--customers': 'Asiakkaat',
        'ui__navi--assignments': 'Toimeksiannot',
        'ui__navi--marketing': 'Markkinointi',
        'ui__navi--intranet': 'Intranet',
        'ui__navi--reports': 'Raportit',
        'ui__navi--settings': 'Asetukset',
        'ui__navi--housing': 'Taloyhtiöt',
        'ui__navi--help': 'Ohjeet',
        'ui__navi--support': 'Etätuki',
        'ui__navi--extranet': 'Extranet',
        'ui__navi--marketing-showing-times': 'Esittelyajat',
        'ui__navi--marketing-markets': 'Markkinapaikat',
        'ui__navi--marketing-magazines': 'Lehdet',
        'ui__navi--customers-search': 'Asiakashaku',
        'ui__navi--customers-leads': 'Asiakasliidit',
        'ui__navi--assignments-sales': 'Myyntitoimeksiannot',
        'ui__navi--assignments-rent': 'Vuokratoimeksiannot',
        'ui__navi--assignments-acquisition': 'Ostotoimeksiannot',
        'ui__navi--reports-rent-list': 'Vuokralista',
        'ui__navi--reports-assignments-acquisition': 'Ostotoimeksiannot',
        'ui__navi--reports-terminated-targets': 'Irtisanotut kohteet',
        'ui__navi--reports-sales-list': 'Myyntilista',
        'ui__navi--reports-journal': 'Päiväkirja',
        'ui__navi--reports-brokerage': 'Välityspalkkio',
        'ui__navi--reports-sold-targets': 'Myydyt kohteet',
        'ui__navi--reports-events-by-customer': 'Tapahtumat asiakkittain',
        'ui__navi--reports-acquisition-channels': 'Hankintakanavat',
        'ui__navi--reports-bills': 'Laskut',
        'ui__navi--reports-sold-targets-by-type': 'Myydyt kohteet tyypeittäin',
        'ui__navi--settings-news': 'Uutiset / tiedotteet',
        'ui__navi--settings-offices-admin': 'Toimipisteet (ylläpito)',
        'ui__navi--settings-offices': 'Toimipisteet',
        'ui__navi--settings-staff-admin': 'Henkilöstö (ylläpito)',
        'ui__navi--settings-staff': 'Henkilöstö',
        'ui__navi--settings-user-groups': 'Käyttäjäryhmät',
        'ui__navi--settings-companies': 'Taloyhtiöt',
        'ui__navi--settings-rescission': 'Reskontra',
        'ui__navi--settings-bills': 'Laskut',
        'ui__navi--settings-magazines': 'Lehdet',
        'ui__navi--settings-about': 'Tietoja ohjelmasta',
        'ui__navi--settings-environment': 'Käyttöympäristö',
        'ui__navi--settings-help': 'Ohjeet',
        'ui__navi--settings-personal-info': 'Omat tiedot',
        'ui__navi--settings-support': 'Etätuki',
        'ui__navi--settings-website-update': 'Sivuston päivitys',
        'ui__navi--settings-jobs': 'Työpaikat',
        'ui__navi--settings-intranet': 'Intranetin hallinta',
        'ui__navi--settings-feedback': 'PDX+ käyttäjäpalaute',
        'ui__navi--settings-export': 'Vie Oikotie/Etuovi',
        'ui__navi--intranet-internal-news': 'Sisäiset tiedotteet',
        'ui__navi--intranet-calendar': 'Tapahtumakalenteri',
        'ui__navi--intranet-offices': 'Toimipisteemme',
        'ui__navi--intranet-person-search': 'Henkilöhaku',
        'ui__navi--intranet-legal': 'Lakitietoa',
        'ui__navi--intranet-document-archive': 'Asiakirjapankki',
        'ui__months-1': 'Tammikuu',
        'ui__months-2': 'Helmikuu',
        'ui__months-3': 'Maaliskuu',
        'ui__months-4': 'Huhtikuu',
        'ui__months-5': 'Toukokuu',
        'ui__months-6': 'Kesäkuu',
        'ui__months-7': 'Heinäkuu',
        'ui__months-8': 'Elokuu',
        'ui__months-9': 'Syyskuu',
        'ui__months-10': 'Lokakuu',
        'ui__months-11': 'Marraskuu',
        'ui__months-12': 'Joulukuu',
        'ui__general--close': 'Sulje',
        'ui__general--loading': 'Ladataan',
        'ui__general--edit': 'Muokkaa',
        'ui__general--edit-stop': 'Lopeta muokkaus',
        'ui__general--add': 'Lisää',
        'ui__general--assign': 'Aseta',
        'ui__general--edit-content': 'Muokkaa sisältöä',
        'ui__general--select-user': 'Valitse käyttäjä',
        'ui__general--select': 'Valitse',
        'ui__general--type': 'Tyyppi',
        'ui__general--status': 'Tila',
        'ui__general--logout': 'Kirjaudu ulos',
        'ui__general--save': 'Tallenna',
        'ui__general--send': 'Lähetä',
        'ui__general--print': 'Tulosta',
        'ui__general--save-and-print': 'Tallenna ja tulosta',
        'ui__general--processing': 'Käsitellään',
        'ui__general--page': 'Sivu',
        'ui__general--documents': 'Dokumentit',
        'ui__general--actions': 'Toiminnot',
        'ui__general--alv': 'ALV',
        'ui__general--reward': 'Palkkio',
        'ui__general--file': 'Tiedosto',
        'ui__general--files': 'Tiedostoa',
        'ui__general--browse': 'Selaa',
        'ui__general--filename': 'Tiedostonimi',
        'ui__general--file-type': 'Tiedoston tyyppi',
        'ui__general--file-to-be-added': 'Lisättävä tiedosto',
        'ui__general--description': 'Kuvaus',
        'ui__general--created': 'Lisäyspvm.',
        'ui__general--download': 'Lataa',
        'ui__general--sign': 'Allekirjoita dokumentti',
        'ui__general--sign-status': 'Allekirjoituksen tila',
        'ui__general--sign-done': 'Allekirjoitus tehty',
        'ui__general--sign-canceled': 'Allekirjoitus peruttu',
        'ui__general--delete': 'Poista',
        'ui__general--cancel': 'Peruuta',
        'ui__general--last-valid-date': 'Viimeinen voimassaolopäivä',
        'ui__general--rejected': 'Hylätty',
        'ui__general--copy-info': 'Kopioi tiedot talteen',
        'ui__general--url': 'Osoite',
        'ui__general--password': 'Salasana',
        'ui__general--selected': 'Valittuna',
        'ui__general--not-selected': 'Ei valittu',
        'ui__general--normal': 'Normaali',
        'ui__general--custom': 'Mukautettu',
        'ui__action--add-file': 'Lisää tiedosto',
        'ui__action--view-file': 'Näytä tiedosto',
        'ui__action--download-file': 'Lataa tiedosto',
        'ui__action--open-details': 'Avaa tarkemmat tiedot',
        'ui__action--download-files': 'Lataa tiedostot',
        'ui__action--send-files-by-email': 'Lähetä tiedostot sähköpostitse',
        'ui__action--delete-files': 'Poista tiedostot',
        'ui__validation--form-errors': 'Täytä ensin tähdellä (*) merkityt pakolliset kentät',
        'ui__validation--required-field': 'Pakollinen kenttä',
        'ui__validation--input-short': 'Arvo on liian lyhyt',
        'ui__validation--input-email': 'Arvo ei ole sähköpostiosoite',
        'ui__validation--number-pos': 'Arvo ei saa olla negatiivinen',
        'ui__validation--number-nan': 'Arvon tulee olla numero',
        'ui__validation--unsupported-file-type': 'Tiedostotyyppi ei ole tuettu.',
        'ui__validation--signer-n-name': 'Allekirjoittajan #$0 nimi.',
        'ui__validation--signer-n-idcode': 'Allekirjoittajan #$0 henkilötunnus.',
        'ui__validation--signer-n-email': 'Allekirjoittajan #$0 email.',
        'ui__error--add-file': 'Tiedoston lisääminen epäonnistui.',
        'ui__error--delete-file': 'Tiedoston poistaminen epäonnistui.',
        'ui__error--delete-files': 'Tiedostojen poistaminen epäonnistui:',
        'ui__error--action-failed': 'Toiminto epäonnistui.',
        'ui__error--saving-failed': 'Tallennus epäonnistui.',
        'ui__error--try-again': 'Yritä hetken päästä uudelleen.',
        'ui__error--connection-timeout': 'Yhteys taustajärjestelmään epäonnistui. Yritä hetken päästä uudelleen.',
        'ui__error--connection-missing': 'Yhteys taustajärjestelmään epäonnistui. Tarkista verkkoyhteys, kiitos.',
        'ui__error--unknown-saving': 'Virhe tiedoston tallennuksessa. Yritä hetken päästä uudelleen.',
        'ui__error--email-failed': 'Sähköpostin lähetys epäonnistui.',
        'ui__info--email-success': 'Sähköpostin lähetys onnistui.',
        'ui__units--volume': 'kpl',
        'ui__filters--filter': 'Suodata',
        'ui__filters--reset': 'Nollaa suodatin',
        'ui__filters--reset-all': 'Nollaa suodattimet',
        'ui__order--sort': 'Järjestys',
        'ui__label--view-type': 'Näkymä',
        'ui__label--view-type-list': 'Lista',
        'ui__label--view-type-images': 'Kuvat',
        'ui__error--no-results': 'Ei tuloksia',
        'ui__search--placeholder': 'Etsi palvelusta..',
        'ui__search-by-name--placeholder': 'Etsi nimellä..',
        'ui__user-menu--profile': 'Käyttäjäprofiili',
        'ui__confirmation--data-saved': 'Tiedot tallennettu',
        'confirm__form--touched': 'Haluatko siirtyä pois? Tallentamattomat muutokset häviävät.',
        'confirm__modal--touched': 'Jatkaessasi tallentamattomat tiedot tallennetaan.',
        'confirm__form--assignment-missing-price':
            'Kaupanteossa maksetaan -arvoa ei ole asetettu. Haluatko silti jatkaa?',
        'confirm__assignment--duplicate': 'Haluatko kopioida toimeksiannon uudeksi?',
        'confirm__file--delete': 'Haluatko poistaa tiedoston',
        'confirm__file--delete-multiple': 'Haluatko poistaa seuraavat tiedostot',
        'confirm__archive--signing-cancel': 'Haluatko peruuttaa tiedoston allekirjoituksen?',
        'contact__general--name': 'Nimi',
        'contact__general--forename': 'Etunimi',
        'contact__general--surname': 'Sukunimi',
        'contact__general--phone': 'Puhelinnumero',
        'contact__general--title': 'Titteli',
        'contact__general--email': 'Email',
        'contact__general--address': 'Osoite',
        'contact__general--zipcode': 'Postinumero',
        'contact__general--city': 'Kaupunki',
        'contact__general--post-office': 'Postitoimipaikka',
        'contact__general--hometown': 'Kotipaikka',
        'contact__general--idcode': 'Henkilötunnus',
        'contact__general--description': 'Lisätietoja',
        'contact__general--person-in-charge': 'Vastuuhenkilö',
        'contact__general--contact-person': 'Yhteyshenkilö',
        'contact__general--assigner': 'Toimeksiantaja',
        'contact__general--assigner-placeholder': 'Hae asiakas tai lisää uusi...',
        'contact__general--assigners': 'Toimeksiantajat',
        'contact__general--approver': 'Suostumuksen antaja',
        'contact__general--warranter': 'Valtakirjan antaja',
        'contact__general--marketing-permission': 'Markkinointilupa',
        'contact__general--apprive-data-saving': 'Suostumus henkilötietojen tallentamiseen',
        'contact__modify--add-new': 'Lisää uusi kontakti',
        'contact__modify--edit': 'Muokkaa kontaktia',
        'contact__type--company': 'Yritys',
        'contact__type--person': 'Henkilö',
        'contact__type--man': 'Mies',
        'contact__type--woman': 'Nainen',
        'contact__type--group': 'Ryhmä',
        'contact__type--heirs': 'Perikunta',
        'contact__header--customer-card': 'Asiakaskortti',
        'contact__header--customer-new': 'Uusi asiakas',
        'company__general--vat-code': 'Y-tunnus',
        'company__general--hometown': 'Kotipaikka',
        'company__general--housing-registry': 'Sähköisessä asunto-osakerekisterissä',
        'company__general--manager-company': 'Isännöitsijätoimisto',
        'company__general--manager-name': 'Isännöitsijän nimi',
        'company__general--manager-address': 'Isännöitsijän osoite',
        'company__general--manager-phone': 'Isännöitsijän puhelin',
        'company__general--manager-cert-date': 'Isännöitsijäntodistuksen pvm',
        'company__general--propertymanagement': 'Kiinteistönhuolto',
        'company__general--feebase': 'Vastikeperuste',
        'company__general--possessions': 'Yhtiö omistaa',
        'company__general--returns-per-year': 'Tuotot/vuosi',
        'company__general--returns-year': 'Tuottojen vuosi',
        'company__general--loans': 'Pitkäaikaiset lainat',
        'company__general--loans-date': 'Lainojen pvm',
        'company__general--redemption': 'Lunastuspykälä (asunnot/muut)',
        'company__general--redemption-carslot': 'Lunastuspykälä (autopaikat)',
        'company__general--restrictions': 'Käyttö- ja luovutusrajoitukset',
        'company__general--obligations': 'Muut velvoitteet',
        'company__general--extra': 'Lisätiedot yhtiöstä',
        'company__general--site-ownership': 'Tontin omistus',
        'company__general--site-ownership-own': 'Oma',
        'company__general--site-ownership-rent': 'Vuokra',
        'company__general--site-ownership-optional-rent': 'Valinnainen vuokra',
        'company__general--site-area': 'Tontin pinta-ala',
        'company__general--rental-site-info': 'Tontin lisätiedot',
        'company__general--landlord': 'Vuokranantaja',
        'company__general--rent-last-date': 'Vuokra-aika (pvm asti)',
        'company__general--rent-per-year': 'Vuokra/vuosi',
        'company__general--built-year': 'Rakennusvuosi',
        'company__general--buildingmaterial': 'Rakennusmateriaali',
        'company__general--roof': 'Kattotyyppi/kate',
        'company__general--floors-total': 'Kerroksia talossa',
        'company__general--warming': 'Lämmitys',
        'company__general--builder': 'Rakentaja',
        'company__general--flats': 'Asuinhuoneistoja',
        'company__general--flat-area': 'Asuinhuoneistojen pinta-ala',
        'company__general--offices': 'Liikehuoneistoja',
        'company__general--officearea': 'Liikehuoneistojen pinta-ala',
        'company__general--volume': 'Tilavuus',
        'company__general--antenna': 'Antenni',
        'company__general--carslots': 'Autopaikat',
        'company__general--sauna': 'Taloyhtiössä sauna',
        'company__general--common-area': 'Muut yhteiset tilat',
        'company__general--ventilation': 'Ilmanvaihto',
        'company__general--broadband': 'Laajakaista',
        'company__general--basic-repairs': 'Tehdyt korjaukset',
        'company__general--coming-repairs': 'Tulevat korjaukset',
        'company__general--new-housing': 'Uudiskohde',
        'company__general--construction-phase': 'Rakennusvaihe',
        'company__general--city': 'Yhtiön kunta',
        'company__general--site-number': 'Tontin nro/Tilan rakennusnro',
        'company__general--construction-bank-office': 'Rakennusvaiheen pankki',
        'company__general--construction-bank-account': 'Rakennusvaiheen tilinumero',
        'company__general--construction-bank-iban': 'Rakennusvaiheen IBAN',
        'company__general--construction-bank-bic': 'Rakennusvaiheen BIC',
        'company__general--bank-office': 'Kauppahinta pankki',
        'company__general--bank-account': 'Kauppahinta tilinumero',
        'company__general--bank-iban': 'Kauppahinta IBAN',
        'company__general--bank-bic': 'Kauppahinta BIC',
        'company__general--barred-bank-office': 'Sulkutili pankki',
        'company__general--barred-bank-account': 'Sulkutili tilinumero',
        'company__general--barred-bank-iban': 'Sulkutili IBAN',
        'company__general--barred-bank-bic': 'Sulkutili BIC',
        'company__general--interest-rate': 'Hyvityskorko',
        'company__general--breach-of-contract': 'Maksuviivästyksen vuoksi purettu kauppa',
        'company__general--breach-of-contract-compensation':
            'Myyjällä oikeus asuntokauppalain mukaiseen vahingonkorvaukseen',
        'company__general--breach-of-contract-fees-and-interest':
            'Ostajan maksettava kulut sekä 2% velattomasta hinnasta',
        'company__general--satety-doc-keeper': 'Turva-asiakirjojen ja osakekirjojen säilyttäjä',
        'company__general--satety-doc-keeper-address': 'Säilytyspaikan osoite',
        'company__general--satety-doc-keeper-signature': 'Turva-asiakirjojen säilyttäjä (allekirjoitus)',
        'company__general--construction-phase-deposit': 'Rakentamisvaiheen vakuus',
        'company__general--incapability-deposit': 'Suorituskyvyttömyysvakuus',
        'company__general--deposit-place-date': 'Vakuuden paikka ja aika',
        'company__general--deposit-attachment': 'Liitteenä todistus, että vakuudet taloyhtiön hallussa',
        'company__general--built-date-earliest': 'Valmis aikaisintaan',
        'company__general--built-date-latest': 'Valmis viimeistään',
        'company__general--built-date-estimated': 'Arvioitu valmistumisaika',
        'company__general--building-permit-applied': 'Rakennuslupaa haettu',
        'company__general--building-permit-info': 'Rakennuslupaan liittyvät lisätiedot',
        'company__general--contract-attachemnts': 'Sopimuksen liitteiden nimet',
        'company__general--contract-attachemnts-carslot': 'Sopimuksen liitteiden nimet (autopaikat)',
        'company__general--contract-attachemnts-office': 'Sopimuksen liitteiden nimet (liiketilat)',
        'company__general--additional-terms': 'Muut ehdot',
        'company__general--additional-terms-carslot': 'Muut ehdot (autopaikat)',
        'company__general--additional-terms-office': 'Muut ehdot (liiketilat)',
        'assignment__headings--contract-info': 'Sopimustiedot',
        'assignment__headings--target-info': 'Kohteen tiedot',
        'assignment__headings--housing': 'Yhtiötiedot',
        'assignment__headings--property': 'Kiinteistötiedot',
        'assignment__headings--building': 'Rakennustiedot',
        'assignment__headings--marketing': 'Kohteen markkinointi',
        'assignment__headings--transaction': 'Kauppatiedot',
        'assignment__headings--contractforms': 'Lomakkeet',
        'assignment__headings--tradebids': 'Tarjouskauppa',
        'assignment__headings--archive': 'Arkisto',
        'assignment__headings--buy-offers': 'Ostotarjoukset',
        'assignment__headings--print-diary': 'Tulosta päiväkirja',
        'assignment__headings--flat-info': 'Huoneiston tiedot',
        'assignment__headings--pricing-fees': 'Hinta- ja asuinkustannukset',
        'assignment__headings--other-info': 'Muut tiedot',
        'assignment__headings--energy-classification': 'Energialuokitus',
        'assignment__headings--internet': 'Internet',
        'assignment__headings--multimedia-links': 'Medialinkit',
        'assignment__headings--photos': 'Kohteen kuvat',
        'assignment__headings--contract-basics': 'Sopimuksen perustiedot',
        'assignment__headings--responsible-persons': 'Vastuuhenkilöt',
        'assignment__headings--bids': 'Ostotarjoukset',
        'assignment__headings--sales-info': 'Kauppatiedot',
        'assignment__headings--sales-tax': 'Varainsiirtoveroilmoitus',
        'assignment__headings--sales-tax-sent': 'Varainsiirtoveroilmoitus lähetetty',
        'assignment__headings--fee-division': 'Palkkion jako edustajille',
        'assignment__headings--select-tax-receiver': 'Valitse luovutuksensaaja',
        'assignment__headings--space-n-storage': 'Tilat ja säilytys',
        'assignment__headings--levels-n-materials': 'Tasot ja materiaalit',
        'assignment__headings--dishwashers': 'Astianpesukoneet',
        'assignment__headings--stoves-n-ovens': 'Liedet, uunit ja keittotasot',
        'assignment__headings--refridgeration': 'Kylmälaitteet',
        'assignment__headings--sauna': 'Sauna',
        'assignment__headings--showers': 'Suihkut',
        'assignment__headings--baths': 'Ammeet',
        'assignment__headings--machinery': 'Kodinkoneet ja LVI-tekniikka',
        'assignment__headings--others': 'Muut',
        'assignment__headings--set-position': 'Aseta sijainti',
        'assignment__headings--floor-material': 'Lattiamateriaali',
        'assignment__label--internet-target-number': 'Kohdenumero',
        'assignment__label--assignment-number': 'Toimeksiantonumero',
        'assignment__headings--extend-contract-terms': 'Jatkosopimuksen ehdot',
        'assignment__label--number': 'Työnro',
        'assignment__label--name': 'Nimi',
        'assignment__label--address': 'Katuosoite',
        'assignment__label--district': 'Kaupunginosa',
        'assignment__label--country': 'Maa',
        'assignment__label--region': 'Maakunta',
        'assignment__label--coordinates': 'Sijainti kartalla',
        'assignment__label--room-count': 'Huoneita',
        'assignment__label--area-living': 'Asuinpinta-ala',
        'assignment__label--area-additional': 'Muu ala',
        'assignment__label--area-total': 'Kokonaisala',
        'assignment__label--area-extra-info': 'Lisätietoja pinta-alasta',
        'assignment__label--balcony-description': 'Lisätietoja parvekkeesta',
        'assignment__label--target-features': 'Kohteen ominaisuudet',
        'assignment__label--other-details': 'Muita tietoja',
        'assignment__label--area-information': 'Lisätietoja pinta-alasta',
        'assignment__label--balcony': 'Parveke',
        'assignment__label--terrace': 'Terassi',
        'assignment__label--sauna': 'Sauna',
        'assignment__label--lift': 'Hissi',
        'assignment__label--asbestos-survey': 'Asbestikartoitus',
        'assignment__label--asbestos-description': 'Lisätietoja asbestikartoituksesta',
        'assignment__label--beach-type': 'Ranta',
        'assignment__label--waterfront-type': 'Rantatyyppi',
        'assignment__label--driving-guide': 'Ajo-ohje',
        'assignment__label--share-nos': 'Osakenumerot',
        'assignment__label--share-count': 'Osakemäärä',
        'assignment__label--share-registry': 'Sähköinen osakekirja',
        'assignment__label--flat-storey': 'Huoneiston kerros',
        'assignment__label--flat-number': 'Huoneiston numero',
        'assignment__label--flat-condition-classification': 'Kuntoluokitus',
        'assignment__label--flat-condition': 'Kunto',
        'assignment__label--flat-kitchen-equipment': 'Keittiön varusteet',
        'assignment__label--flat-kitchen': 'Keittiön lisätiedot',
        'assignment__label--flat-wc': 'WC',
        'assignment__label--livingroom': 'Olohuone',
        'assignment__label--bedrooms': 'Makuuhuoneet',
        'assignment__label--flat-bathrooms': 'Kylpyhuoneet',
        'assignment__label--flat-utility-room': 'Kodinhoitohuone',
        'assignment__label--flat-extra': 'Lisätietoja',
        'assignment__label--flat-extra_brochure': 'Lisätietoja (vain esite)',
        'assignment__label--foundation_type': 'Perustamistapa',
        'assignment__label--trade-bid-assignment': 'Tarjouskauppakohde',
        'assignment__label--trade-bid-target-debtprice': 'Tavoitehinta',
        'assignment__label--trade-bid-latest': 'Korkein voimassaoleva tarjous',
        'assignment__label--source-channel': 'Hankintakanava',
        'assignment__label--contract-created': 'Toimeksiantosopimus on tehty',
        'assignment__label--salesprice': 'Myyntihinta',
        'assignment__label--salesprice-start': 'Lähtöhinta',
        'assignment__label--debtprice-goal': 'Velaton tavoitehinta',
        'assignment__label--targetprice-mortgage-debt': 'Panttikirjat',
        'assignment__label--targetprice-mortgage-debt-free': 'Vapaat panttikirjat',
        'assignment__label--targetprice-mortgage-debt-keeping': 'Panttikirjojen säilytys',
        'assignment__label--targetprice-stress-proof-date': 'Rasitustodistuksen pvm',
        'assignment__label--targetprice-property-tax': 'Kiinteistövero',
        'assignment__label--targetprice-water-total': 'Vesimaksu',
        'assignment__label--targetprice-cleaning': 'Puhtaanapito',
        'assignment__label--targetprice-warming': 'Lämmityskustannukset',
        'assignment__label--targetprices-total': 'Kustannukset yhteensä',
        'assignment__label--targetprice-oil-use': 'Öljynkulutus',
        'assignment__label--targetprice-electric-use': 'Lämmityskulut',
        'assignment__label--targetprice-electric-consumption': 'Sähkon kulutus',
        'assignment__label--debtpart': 'Velkaosuus',
        'assignment__label--debtprice-bid': 'Velaton lähtöhinta',
        'assignment__label--debtprice': 'Velaton myyntihinta',
        'assignment__label--debtpart-date': 'Velkaosuuden päiväys',
        'assignment__label--debtprice-final': 'Toteutunut velaton hinta',
        'assignment__label--targetprice-loanpayable': 'Laina poismaksettavissa',
        'assignment__label--targetprice-care': 'Hoitovastike',
        'assignment__label--targetprice-care-sq': 'Hoitovastike neliöltä',
        'assignment__label--targetprice-financing': 'Rahoitusvastike',
        'assignment__label--targetprice-repair': 'Korjausvastike',
        'assignment__label--targetprice-financing-sq': 'Rahoitusvastike neliöltä',
        'assignment__label--targetprice-repair-sq': 'Korjausvastike neliöltä',
        'assignment__label--housingtotalfee': 'Kokonaisvastike',
        'assignment__label--housingtotalfee-sq': 'Kokonaisvastike neliöltä',
        'assignment__label--targetprice-rent-per-month': 'Vuokratulo',
        'assignment__label--targetprice-other-payments': 'Muut maksut',
        'assignment__label--targetprice-other-buyers-payments': 'Muut ostajan maksut',
        'assignment__label--targetprice-water': 'Vesimaksu (hlö)',
        'assignment__label--targetprice-waterspec': 'Vesimaksun lisätiedot',
        'assignment__label--target-freedate-type': 'Vapautuminen',
        'assignment__label--target-freedate': 'Vapautumispäivä',
        'assignment__label--target-freedate-text': 'Vapautumisen lisätiedot',
        'assignment__label--target-lodger': 'Vuokralainen',
        'assignment__label--target-lodger-phone': 'Vuokralaisen puhelin',
        'assignment__label--target-lodgerlivedsince': 'Vuokralainen asunut lähtien',
        'assignment__label--target-revoking': 'Vuokrasuhteen  irtisanominen',
        'assignment__label--target-revoking-date': 'Vuokrasuhteen irtisanomisen pvm',
        'assignment__label--target-revoker': 'Irtisanoja',
        'assignment__label--target-holding-type': 'Omistusmuoto',
        'assignment__label--targetprice-payment-method': 'Maksutapa',
        'assignment__label--target-burdens': 'Rasitteet/oikeudet',
        'assignment__label--target-deed-restrictions': 'Saantorajoitteet',
        'assignment__label--certified-owner': 'Lainhuuto-oikeuden haltija',
        'assignment__label--acquisition-type-date': 'Saantotapa ja pvm',
        'assignment__label--target-modifications': 'Korjaukset',
        'assignment__label--target-extra': 'Lisätiedot',
        'assignment__label--target-services': 'Alueen palvelut',
        'assignment__label--target-schools': 'Koulut ja päiväkodit',
        'assignment__label--target-transport': 'Liikenneyhteydet',
        'assignment__label--property-planning': 'Kaavoitustilanne',
        'assignment__label--property-infosource': 'Kaavoituksen lisätiedot antaa',
        'assignment__label--planning-desc': 'Lisätietoja kaavoituksesta',
        'assignment__label--target-class': 'Kohdeluokka',
        'assignment__label--costlocation': 'Kust.paikka',
        'assignment__label--target-type': 'Kohdetyyppi',
        'assignment__label--target-details': 'Tyypin tarkennus',
        'assignment__label--energy-class': 'Energialuokka',
        'assignment__label--energy-certificate': 'Energiatodistus',
        'assignment__label--energy-certificate-desc': 'Energiatodistuksen lisätiedot',
        'assignment__label--data-visibility': 'Näkyvyys',
        'assignment__label--oikotie': 'Oikotie',
        'assignment__label--etuovi': 'Etuovi',
        'assignment__label--toimitilatfi': 'Toimitilat',
        'assignment__label--sales-brochure': 'Myyntiesite',
        'assignment__label--window-display': 'Ikkunakortti',
        'assignment__label--window-display-marketing': 'Ikkunanäyttö',
        'assignment__label--website': 'Kotisivut',
        'assignment__label--heading': 'Otsikko',
        'assignment__label--internet-text': 'Esittelyteksti',
        'assignment__label--brochure-text': 'Esitteen esittelyteksti',
        'assignment__label--windowcard-text': 'Ikkunakortin esittelyteksti',
        'assignment__label--virtual-presentation': 'Virtuaaliesittely',
        'assignment__label--videoclip': 'Videoleike',
        'assignment__label--marketing-link': 'Uudiskohteen linkki',
        'assignment__label--construction-stage': 'Rak.vaihe',
        'assignment__label--flat-count': 'Asuntolkm',
        'assignment__label--office': 'Toimisto',
        'assignment__label--description': 'Tilakuvaus',
        'assignment__label--description-abr': 'Kuvaus',
        'assignment__label--customer': 'Asiakas',
        'assignment__label--dealer': 'Välittäjä',
        'assignment__label--person': 'Hankkija #1',
        'assignment__label--person2': 'Hankkija #2',
        'assignment__label--person3': 'Hoitaja',
        'assignment__label--person4': 'Myyjä',
        'assignment__label--split1': 'Hankkija #1 jako',
        'assignment__label--split2': 'Hankkija #2 jako',
        'assignment__label--split3': 'Hoitaja jako',
        'assignment__label--split4': 'Myyjä jako',
        'assignment__label--validity': 'Voimassaolo',
        'assignment__label--contract-date': 'Sopimuksen päiväys',
        'assignment__label--commission': 'Välityspalkkio',
        'assignment__label--housing': 'Taloyhtiö',
        'assignment__label--target-new': 'Uudiskohde',
        'assignment__label--target-holiday': 'Lomakohde',
        'assignment__label--validity-start': 'Voimassaolo alkaa',
        'assignment__label--validity-end': 'Voimassaolo päättyy',
        'assignment__label--validity-continues': 'Sopimus jatkuu 1kk kerrallaan',
        'assignment__label--internal-memo': 'Lisätiedot sisäiseen käyttöön',
        'assignment__label--buyer-customer': 'Ostaja',
        'assignment__label--buyer-customer-2': 'Ostaja #2',
        'assignment__label--diary-salesprice': 'Toteutunut myyntihinta',
        'assignment__label--deal-downpayment-amount': 'Käsiraha',
        'assignment__label--deal-downpayment-date': 'Käsirahan maksupvm',
        'assignment__label--deal-on-deal-amount': 'Kaupanteossa maksettava',
        'assignment__label--deal-date': 'Kaupantekopvm',
        'assignment__label--deal-final-payment': 'Kauppahinnan loppuerä',
        'assignment__label--deal-moving-date': 'Muuttopvm',
        'assignment__label--deal-last-payment': 'Loppukauppahinnan maksuehdot',
        'assignment__label--deal-possession-transfers': 'Hallintaoikeus siirtyy',
        'assignment__label--deal-ownership-transfer-date': 'Omistusoikeuden siirtymispvm',
        'assignment__label--deed-edit': 'Muokkaa kauppakirjaa',
        'assignment__label--deed-create': 'Luo kauppakirja',
        'assignment__label--deal-type': 'Kaupan tyyppi',
        'assignment__label--cash-deal': 'Käteiskauppa',
        'assignment__label--installment-payment': 'Osamaksukauppa',
        'assignment__label--ownership-transfers': 'Omistusoikeus siirtyy',
        'assignment__label--deal-fee-substract': 'Palkkion vähennys',
        'assignment__label--deal-fee-substract-reason': 'Palkkion vähennyksen syy',
        'assignment__label--flat-total-storeys': 'Kerrosluku',
        'assignment__label--property-usagetype': 'Tilat/pinta-alat/muut rakennukset',
        'assignment__label--property-type': 'Kiinteistön tyyppi',
        'assignment__label--property-city-type': 'Kunta vai kaupunki',
        'assignment__label--unseparated-parcel': 'Määräala',
        'assignment__label--property-identifier': 'Kiinteistötunnus',
        'assignment__label--property-registration-date': 'Kiinteistörekisterin pvm',
        'assignment__label--property-city': 'Kunta/kaupunki',
        'assignment__label--property-district': 'Kylä/kaupunginosa',
        'assignment__label--property-name': 'Tilan nimi',
        'assignment__label--property-reg-no': 'Rekisterinumero',
        'assignment__label--property-block': 'Kortteli/tila',
        'assignment__label--property-site-number': 'Tilan numero',
        'assignment__label--property-rent-ends': 'Vuokra-aika päättyy',
        'assignment__label--property-rent': 'Vuokra',
        'assignment__label--property-tenancy-handover': 'Vuokraoikeuden siirto',
        'assignment__label--property-landscape': 'Maasto',
        'assignment__label--property-soil': 'Maaperä/kasvusto',
        'assignment__label--property-limitation': 'Alueen käyttörajoitukset',
        'assignment__label--property-building-right': 'Rakennusoikeus',
        'assignment__label--property-public-utility': 'Kunnallistekniikka',
        'assignment__label--property-water': 'Käyttövesi',
        'assignment__label--property-sewage': 'Viemäri',
        'assignment__label--property-access': 'Liittymät (tontties.)',
        'assignment__label--property-extra': 'Lisätiedot',
        'assignment__label--tax-bailor': 'Luovuttaja',
        'assignment__label--tax-receiver': 'Luovutuksensaaja',
        'assignment__label--tax-idcode': 'Hetu/Y-tunnus',
        'assignment__label--tax-share': 'Osuus',
        'assignment__label--tax-first-time-buyer': 'Ensiasunnon ostaja',
        'assignment__label--bank-transfer': 'Tilisiirto',
        'assignment__label--tax-is-moving': 'Muuttaa mukana',
        'assignment__label--tax-capital-transfer-relative': 'Sukulaisluovutus',
        'assignment__label--tax-capital-transfer-dealer-trade': 'Välittäjäkauppa',
        'assignment__label--tax-capital-transfer-company-partner':
            'Oliko ostaja jo ennen kauppaa osakkaana tai yhtiömiehenä myyjäyhtiössä tai onko ostaja myyjäyhtiön osakkaan tai yhtiömiehen vanhempi, puoliso tai lapsi?',
        'assignment__label--tax-capital-transfer-seller-company-partner':
            'Onko myyjä ostajayhtiön osakas tai yhtiömies tai ostajayhtiön osakkaan tai yhtiömiehen vanhempi, puoliso tai lapsi?',
        'assignment__label--tax-capital-transfer-internal-company-partner':
            'Onko kyseessä yhtiön ja sen osakkaan tai yhtiömiehen välinen luovutus tai konsernin sisäinen luovutus?',
        'assignment__label--capital-transfer-tax-percent': 'Varainsiirtoveroprosentti',
        'assignment__label--capital-transfer-tax': 'Varainsiirtovero',
        'assignment__label--capital-transfer-debtpart-tax': 'Varainsiirtovero yhtiölainaosuudesta',
        'assignment__label--capital-transfer-payday': 'Eräpäivä',
        'assignment__label--capital-transfer-regular-flat-date': 'Vakituinen asukas alkaen',
        'assignment__label--capital-transfer-flat-area': 'Asuntona käytettävä osuus',
        'assignment__label--transfer-tax-form-date': 'Ilmoituksen pvm',
        'assignment__label--tax-company-form': 'Ilmoittajana yrityksemme',
        'assignment__label--tax-payable': 'Maksettava osuus',
        'assignment__label--tax-paid-comfirmation': 'Vakuutan, että kaikkien ostajien varainsiirtoverot on maksettu',
        'assignment__commission--fee-type': 'Palkkion tyyppi',
        'assignment__commission--fee-type-percent': 'Prosenttiosuus',
        'assignment__commission--fee-type-fixed': 'Kiinteä alkaen',
        'assignment__commission--fee-percent': 'Sovittu palkkio-%',
        'assignment__commission--fee-incl-tax': 'Sis. ALV 24%',
        'assignment__commission--fee-from-debt': 'Velattomasta hinnasta',
        'assignment__commission--min-fixed-fee': 'Kiinteä palkkio vähintään',
        'assignment__commission--fee-substract': 'Palkkiosta vähennettävä €',
        'assignment__commission--fee-substract-reason': 'Palkkion vähennyksen syy',
        'assignment__commission--fee-with-tax': 'Palkkio (ALV 24%) €',
        'assignment__commission--fee-with-no-tax': 'Palkkio (ALV 0%) €',
        'assignment__commission--fee-payment-date': 'Palkkion maksupäivä',
        'assignment__commission--fee-details': 'Palkkion yksityiskohdat',
        'assignment__status--draft': 'Kesken',
        'assignment__status--premarketing': 'Enn.markk',
        'assignment__status--on-sale': 'Myynnissä',
        'assignment__status--free': 'Vapaa',
        'assignment__status--sold': 'Myyty',
        'assignment__target-type--flats': 'Asunnot',
        'assignment__target-type--offices': 'Liiketilat',
        'assignment__target-type--storages': 'Varastotilat',
        'assignment__target-type--carslots': 'Autopaikat',
        'assignment__target-type--block': 'Kerrostalo',
        'assignment__target-type--rowhouse': 'Rivitalo',
        'assignment__target-type--semi': 'Paritalo',
        'assignment__target-type--carslot': 'Autopaikka',
        'assignment__action--fill-persons': 'Esitäytä henkilöt',
        'assignment__action--count-fee': 'Laske palkkio',
        'assignment__action--add-new-housing': 'Lisää uusi taloyhtiö',
        'assignment__action--cancel-new-housing': 'Peru taloyhtion lisäys',
        'assignment__action--send-capital-transfer-form': 'Lähetä varainsiirtoverolmoitus',
        'assignment__action--do-capital-transfer-payment': 'Tulosta tilisiirrot',
        'assignment__action--create-new': 'Luo uusi toimeksianto',
        'assignment__action--finalize-sale': 'Päätä kauppaan',
        'assignment__action--invoice-fee': 'Tee palkkiolasku',
        'assignment__action--view-ad': 'Katso ilmoitus',
        'assignment__action--status-incomplete-sold': 'Peru kauppa',
        'assignment__action--status-incomplete-terminated': 'Palauta irtisanottu',
        'assignment__action--status-sale': 'Merkitse myytäväksi',
        'assignment__action--status-reserved': 'Merkitse varatuksi',
        'assignment__action--status-sale-reserved': 'Palauta myyntiin',
        'assignment__action--status-terminated': 'Irtisano',
        'assignment__action--extend-contract': 'Jatka sopimusta',
        'assignment__action--duplicate': 'Kopioi uudeksi',
        'assignment__error--fee-splits-mismatch':
            'Palkkiojakoprosenttien summa ei ole 100%. Jatkatko siitä huolimatta?',
        'assignment__error--fee-data-missing': 'Täytä palkkiotiedot',
        'assignment__error--seller-missing': 'Lisää myyjä',
        'housing__heading--contract-info': 'Kauppakirjan tiedot',
        'housing__label--flat-reservations': 'Varatut / Myydyt / Yhteensä',
        'brochure__heading--settings': 'Esitteen asetukset',
        'brochure__heading--create': 'Luo esite toimeksiannosta',
        'brochure__label--printer': 'Esitteen tulostaja',
        'brochure__label--template': 'Esitepohja',
        'brochure__label--compressible': 'Tiivis taitto',
        'brochure__text--page': 'Sivu',
        'brochure__text--info': 'Kohteen tiedot',
        'brochure__text--infocon': 'Kohteen tiedot (jatkuu)',
        'brochure__text--floorplan': 'Pohjakuva',
        'brochure__text--footer': 'Alatunniste',
        'brochure__text--logo': 'Logo',
        'brochure__text--main-image': 'Pääkuva',
        'brochure__text--basic-info': 'Perustiedot',
        'brochure__text--description': 'Esittelyteksti',
        'brochure__text--target-image': 'Kohdekuva',
        'brochure__select-option--empty-cover': 'tyhjä kansilehti',
        'brochure__select-option--photo': 'kuva',
        'displaybrochure__label--demonstrator': 'Esittelijä',
        'displaybrochure__label--nodemonstrator': 'Ei esittelijää',
        'displaybrochure__heading--config': 'Ikkunakortin asetukset',
        'displaybrochure__heading--create': 'Luo ikkunakortti toimeksiannosta',
        'pricelist__heading--settings': 'Hinnaston asetukset',
        'pricelist__heading--fields': 'Hinnaston kentät',
        'pricelist__heading--extra': 'Lisävalinnat',
        'pricelist__heading--date': 'Hinnaston päiväys',
        'pricelist__settings--show-salesprices': 'Näytä myytyjen hinnat',
        'pricelist__settings--show-salesprices-reserved': 'Näytä varattujen hinnat',
        'pricelist__settings--hide-sold': 'Piilota myydyt',
        'pricelist__settings--show-exact-status': 'Näytä tarkka varaustilanne',
        'widgets__title--yield-multi': 'Tulos 6kk / €',
        'widgets__title--yield-volume': 'Tulos 6kk / kpl',
        'widgets__title--yield-current': 'Tulos nyt',
        'widgets__title--news': 'Tiedotteet',
        'widgets__title--leads': 'Liidit',
        'widgets__title--assignments': 'Toimeksiannot',
        'widgets__title--customer-actions': 'Asiaakaan lisäys',
        'widget__lead--actions-interest': 'Kohdekiinnostus',
        'widget__lead--actions-contact': 'Yhteystiedot',
        'widget__lead--actions-transfer': 'Siirrä käyttäjälle',
        'widget__lead--label-transfer': 'Siirrä',
        'widget__lead--label-transfer-done': 'Siirretty',
        'widget__yield--result-label': 'Tavoiteesta saavutettu',
        'widget__yield--goal': 'Tavoite',
        'widget__yield--result': 'Tulos',
        'widget__yield--reserve': 'Varaukset',
        'archive__heading--add-file': 'Tiedoston lisäys',
        'archive__heading--digital-signing': 'Sähköinen allekirjoitus',
        'archive__heading--signers': 'Allekirjoittajat',
        'archive__heading--send-files-email': 'Lähetä tiedostoja sähköpostitse',
        'archive__label--enable-inhouse': 'Lisää välittäjan edustaja allekirjoittajaksi',
        'archive__label--signing-inhouse-person': 'Allekirjoittava välittäjän edustaja',
        'archive__label--add-signer': 'Lisää allekirjoittaja',
        'archive__label--signed-file': 'Sähköisesti allekirjoitettava tiedosto',
        'archive__label--inviter': 'Kutsuja',
        'archive__label--files-to-send': 'Lähetettävät tiedostot',
        'archive__label--sender-name': 'Lähettäjän nimi',
        'archive__label--sender-email': 'Lähettäjän sähköpostiosoite',
        'archive__label--receiver-email': 'Vastaanottajan sähköpostiosoite',
        'archive__label--message': 'Viesti',
        'archive__action--send-to-signing': 'Lähetä allekirjoitettavaksi',
        'archive__action--cancel-signing': 'Peruuta allekirjoitus',
        'archive__action--signing-waiting-sending': 'odottaa kutsun lähetystä',
        'archive__action--signing-waiting-signing': 'odottaa allekirjoitusta',
        'archive__action--signing-signed': 'allekirjoitettu',
        'archive__action--signing-canceled': 'peruutettu',
        'archive__action--given-information': 'Annettu salasana ja osoite',
        'tooltip__assignment--asbestos-survey':
            'Ennen vuotta 1994 rakennetuissa kohteissa on ilmoitettava onko asbestikartoitus tehty.',
        'tooltip__assignment--trade-bid-process': 'Tarjosukauppaprosessi tapahtuu `Tarjouskauppa`-sivulla.',
        'tooltip__assignment--condition-classification': `etuovi.com:
"Erinomainen" = "Hyvä",
"Huono" = "Välttävä"`,
        'tooltip__assignment--flat-extra-brochure': 'Muuta kauppaan kuuluvaa. Esimerkiksi ilmalämpöpumppu.',
        'tooltip__assignment--energy-certificate-desc': 'Lisätietoja energiatodistuksesta tai sen tarpeettomuudesta.',
        'tooltip__assignment--save-before-transfer': 'Tallenna dokumentti tilisiirtotietojen luomiseksi, kiitos.',
        'tooltip__assignment--sales-tax': `Huom!

Jos ulkomaalainen ostaja ei ole Verohallinnon rekisterissä, täytyy ostajan viitenumeron saamiseksi rekisteröityä asiakkaaksi Verohallintoon ennen kauppaa.

Rekisteröinti käsitellään Verohallinnossa korkealla prioriteetilla, mutta asia on hoidettava hyvissä ajoin ennen kaupantekoa.`,
        'assignment__placeholder--tax-share': 'Esim. 1/2',
    },
    en: {
        'ui__navi--dashboard': 'Dashboard',
        'ui__navi--customers': 'Customers',
        'ui__navi--assignments': 'Assignments',
        'ui__navi--marketing': 'Marketing',
        'ui__navi--intranet': 'Intranet',
        'ui__navi--reports': 'Reports',
        'ui__navi--settings': 'Settings',
        'ui__navi--housing': 'Housing',
        'ui__navi--help': 'Help',
        'ui__navi--support': 'Support',
        'ui__navi--extranet': 'Extranet',
        'ui__navi--marketing-showing-times': 'Showing times',
        'ui__navi--marketing-markets': 'Markets',
        'ui__navi--marketing-magazines': 'Magazines',
        'ui__navi--customers-search': 'Customer search',
        'ui__navi--customers-leads': 'Customer leads',
        'ui__navi--assignments-sales': 'Sales assignments',
        'ui__navi--assignments-rent': 'Rent assignments',
        'ui__navi--assignments-acquisition': 'Acquisition assignments',
        'ui__navi--reports-rent-list': 'Rent list',
        'ui__navi--reports-assignments-acquisition': 'Acquisition assignments',
        'ui__navi--reports-terminated-targets': 'Terminated targets',
        'ui__navi--reports-sales-list': 'Sales list',
        'ui__navi--reports-journal': 'Journal',
        'ui__navi--reports-brokerage': 'Brokerage',
        'ui__navi--reports-sold-targets': 'Sold targets',
        'ui__navi--reports-events-by-customer': 'Events by customer',
        'ui__navi--reports-acquisition-channels': 'Acquisition channels',
        'ui__navi--reports-bills': 'Bills',
        'ui__navi--reports-sold-targets-by-type': 'Sold targets by type',
        'ui__navi--settings-news': 'News',
        'ui__navi--settings-offices-admin': 'Offices (admin)',
        'ui__navi--settings-offices': 'Offices',
        'ui__navi--settings-staff-admin': 'Staff (admin)',
        'ui__navi--settings-staff': 'Staff',
        'ui__navi--settings-user-groups': 'User groups',
        'ui__navi--settings-companies': 'Companies',
        'ui__navi--settings-rescission': 'Rescission',
        'ui__navi--settings-bills': 'Bills',
        'ui__navi--settings-magazines': 'Magazines',
        'ui__navi--settings-about': 'About PDX',
        'ui__navi--settings-environment': 'Usage environment',
        'ui__navi--settings-help': 'Help',
        'ui__navi--settings-personal-info': 'Personal info',
        'ui__navi--settings-support': 'Support',
        'ui__navi--settings-website-update': 'Website update',
        'ui__navi--settings-jobs': 'Jobs',
        'ui__navi--settings-intranet': 'Intranet',
        'ui__navi--settings-feedback': 'PDX+ feedback',
        'ui__navi--settings-export': 'Data export',
        'ui__navi--intranet-internal-news': 'Internal news',
        'ui__navi--intranet-calendar': 'Event calendar',
        'ui__navi--intranet-offices': 'Our offices',
        'ui__navi--intranet-person-search': 'Person search',
        'ui__navi--intranet-legal': 'Legal information',
        'ui__navi--intranet-document-archive': 'Document archive',
        'ui__months-1': 'January',
        'ui__months-2': 'February',
        'ui__months-3': 'March',
        'ui__months-4': 'April',
        'ui__months-5': 'May',
        'ui__months-6': 'June',
        'ui__months-7': 'July',
        'ui__months-8': 'August',
        'ui__months-9': 'September',
        'ui__months-10': 'October',
        'ui__months-11': 'November',
        'ui__months-12': 'December',
        'ui__general--close': 'Close',
        'ui__general--loading': 'Loading',
        'ui__general--edit': 'Edit',
        'ui__general--edit-stop': 'Stop editing',
        'ui__general--add': 'Add',
        'ui__general--assign': 'Assign',
        'ui__general--edit-content': 'Edit content',
        'ui__general--select-user': 'Select user',
        'ui__general--select': 'Select',
        'ui__general--type': 'Type',
        'ui__general--status': 'Status',
        'ui__general--logout': 'Log out',
        'ui__general--save': 'Save',
        'ui__general--send': 'Send',
        'ui__general--print': 'Print',
        'ui__general--save-and-print': 'Save and Print',
        'ui__general--processing': 'Processing',
        'ui__general--page': 'Page',
        'ui__general--documents': 'Documents',
        'ui__general--actions': 'Actions',
        'ui__general--alv': 'Sales tax',
        'ui__general--reward': 'Reward',
        'ui__general--file': 'File',
        'ui__general--files': 'Files',
        'ui__general--browse': 'Browse',
        'ui__general--filename': 'File name',
        'ui__general--file-type': 'File type',
        'ui__general--file-to-be-added': 'File',
        'ui__general--description': 'Description',
        'ui__general--created': 'Created',
        'ui__general--download': 'Download',
        'ui__general--sign': 'Sign document',
        'ui__general--sign-status': 'Signing status',
        'ui__general--sign-done': 'Signing done',
        'ui__general--sign-canceled': 'Signing cancelled',
        'ui__general--delete': 'Delete',
        'ui__general--cancel': 'Cancel',
        'ui__general--last-valid-date': 'Valid until',
        'ui__general--rejected': 'Rejected',
        'ui__general--copy-info': 'Please copy information',
        'ui__general--url': 'Address',
        'ui__general--password': 'Password',
        'ui__general--selected': 'Selected',
        'ui__general--not-selected': 'Not selected',
        'ui__general--normal': 'Normal',
        'ui__general--custom': 'Custom',
        'ui__action--add-file': 'Add file',
        'ui__action--view-file': 'View file',
        'ui__action--download-file': 'Download file',
        'ui__action--open-details': 'Open details',
        'ui__action--download-files': 'Download files',
        'ui__action--send-files-by-email': 'Send files by email',
        'ui__action--delete-files': 'Delete files',
        'ui__validation--form-errors': 'Please fill the fields marked by star (*) first',
        'ui__validation--required-field': 'Required field',
        'ui__validation--input-short': 'Input is too short',
        'ui__validation--input-email': 'Input is not email',
        'ui__validation--number-pos': 'Positive number required',
        'ui__validation--number-nan': 'Number required',
        'ui__validation--unsupported-file-type': 'Unsupported file type.',
        'ui__validation--signer-n-name': 'Signer #$0 name',
        'ui__validation--signer-n-idcode': 'Signer #$0 identifier',
        'ui__validation--signer-n-email': 'Signer #$0 email',
        'ui__error--add-file': 'Adding file was unsuccesful.',
        'ui__error--delete-file': 'Deleting the file was unsuccessful.',
        'ui__error--delete-files': 'Deleting files was unsuccessful.',
        'ui__error--action-failed': 'Action failed.',
        'ui__error--saving-failed': 'Saving failed.',
        'ui__error--try-again': 'Please try again in a moment.',
        'ui__error--connection-timeout': 'Connection timeout failure. Please try again in a moment.',
        'ui__error--connection-missing': 'Connection failure. Please check your network connection.',
        'ui__error--unknown-saving': 'An error occurred while trying save. Please try again in a moment.',
        'ui__error--email-failed': 'Sending the email failed.',
        'ui__info--email-success': 'Sending the email was succesful.',
        'ui__units--volume': '',
        'ui__filters--filter': 'Filter',
        'ui__filters--reset': 'Reset filter',
        'ui__filters--reset-all': 'Reset filters',
        'ui__order--sort': 'Order',
        'ui__label--view-type': 'View',
        'ui__label--view-type-list': 'List',
        'ui__label--view-type-images': 'Images',
        'ui__error--no-results': 'No results',
        'ui__search--placeholder': 'Search..',
        'ui__search-by-name--placeholder': 'Search by name..',
        'ui__user-menu--profile': 'Profile settings',
        'ui__confirmation--data-saved': 'Data saved',
        'confirm__form--touched': 'Discard usaved changes?',
        'confirm__modal--touched': 'Continue and save unsaved data',
        'confirm__form--assignment-missing-price': 'Paid on deal price is not defined. Do you want to continue?',
        'confirm__assignment--duplicate': 'Do you want to copy current assignment as new?',
        'confirm__file--delete': 'Do you want to delete the file',
        'confirm__file--delete-multiple': 'Do you want to delete following files',
        'confirm__archive--signing-cancel': 'Do you want to cancel sining the file?',
        'contact__general--name': 'Name',
        'contact__general--forename': 'Forename',
        'contact__general--surname': 'Surname',
        'contact__general--phone': 'Phone',
        'contact__general--title': 'Title',
        'contact__general--email': 'Email',
        'contact__general--address': 'Address',
        'contact__general--zipcode': 'Zipcode',
        'contact__general--city': 'City',
        'contact__general--post-office': 'Post office',
        'contact__general--hometown': 'Hometown',
        'contact__general--idcode': 'ID code',
        'contact__general--description': 'Description',
        'contact__general--person-in-charge': 'Person in charge',
        'contact__general--contact-person': 'Contact person',
        'contact__general--assigner': 'Assigner',
        'contact__general--assigner-placeholder': 'Search for customer or add new...',
        'contact__general--assigners': 'Assigners',
        'contact__general--approver': 'Approver',
        'contact__general--warranter': 'Warranter',
        'contact__general--marketing-permission': 'Marketing permission',
        'contact__general--apprive-data-saving': 'Approve storing of personal data',
        'contact__modify--add-new': 'Add new contact',
        'contact__modify--edit': 'Edit contact',
        'contact__type--company': 'Company',
        'contact__type--person': 'Person',
        'contact__type--man': 'Man',
        'contact__type--woman': 'Woman',
        'contact__type--group': 'Group',
        'contact__type--heirs': 'Heir collective',
        'contact__header--customer-card': 'Customer',
        'contact__header--customer-new': 'New customer',
        'company__general--vat-code': 'VAT Code',
        'company__general--hometown': 'Hometown',
        'company__general--housing-registry': 'Electronic housing registry',
        'company__general--manager-company': 'Manager company',
        'company__general--manager-name': 'Manager name',
        'company__general--manager-address': 'Manager address',
        'company__general--manager-phone': 'Manager phone',
        'company__general--manager-cert-date': 'Manager certificate date',
        'company__general--propertymanagement': 'Property management',
        'company__general--feebase': 'Fee base',
        'company__general--possessions': 'Possessions',
        'company__general--returns-per-year': 'Returns per year',
        'company__general--returns-year': 'Year of returns',
        'company__general--loans': 'Long term loans',
        'company__general--loans-date': 'Loans date',
        'company__general--redemption': 'Redemption clause (apts/others)',
        'company__general--redemption-carslot': 'Redemption clause (carslots)',
        'company__general--restrictions': 'Usage and handover restrictions',
        'company__general--obligations': 'Other obligations',
        'company__general--extra': 'Extra info on company',
        'company__general--site-ownership': 'Site ownership',
        'company__general--site-ownership-own': 'Own',
        'company__general--site-ownership-rent': 'Rented',
        'company__general--site-ownership-optional-rent': 'Rent optional',
        'company__general--site-area': 'Site area',
        'company__general--rental-site-info': 'Rental site description',
        'company__general--landlord': 'Landlord',
        'company__general--rent-last-date': 'Rend valid until',
        'company__general--rent-per-year': 'Rent per year',
        'company__general--built-year': 'Year built',
        'company__general--buildingmaterial': 'Building material',
        'company__general--roof': 'Roofing type',
        'company__general--floors-total': 'Building floors',
        'company__general--warming': 'Warming',
        'company__general--builder': 'Builder',
        'company__general--flats': 'Flats',
        'company__general--flat-area': 'Flat area',
        'company__general--offices': 'Offices',
        'company__general--officearea': 'Office area',
        'company__general--volume': 'Volume',
        'company__general--antenna': 'Antenna',
        'company__general--carslots': 'Car slots',
        'company__general--sauna': 'Common sauna in building',
        'company__general--common-area': 'Other common areas',
        'company__general--ventilation': 'Ventilation',
        'company__general--broadband': 'Broadband',
        'company__general--basic-repairs': 'Basic repairs',
        'company__general--coming-repairs': 'Coming repairs',
        'company__general--new-housing': 'New housing',
        'company__general--construction-phase': 'Construction phase',
        'company__general--city': 'Company city',
        'company__general--site-number': 'Site number',
        'company__general--construction-bank-office': 'Construction time bank',
        'company__general--construction-bank-account': 'Construction time account number',
        'company__general--construction-bank-iban': 'Construction time IBAN',
        'company__general--construction-bank-bic': 'Construction time BIC',
        'company__general--bank-office': 'Sales price bank',
        'company__general--bank-account': 'Sales price account number',
        'company__general--bank-iban': 'Sales price IBAN',
        'company__general--bank-bic': 'Sales price BIC',
        'company__general--barred-bank-office': 'Barred account bank',
        'company__general--barred-bank-account': 'Barred account number',
        'company__general--barred-bank-iban': 'Barred account IBAN',
        'company__general--barred-bank-bic': 'Barred account BIC',
        'company__general--interest-rate': 'Interest',
        'company__general--breach-of-contract': 'Breach of contract due to late payment',
        'company__general--breach-of-contract-compensation':
            'Seller has right to compensation of losses as per Housing Transactions Act',
        'company__general--breach-of-contract-fees-and-interest': 'Buyer pays fees and 2% of debt free price',
        'company__general--satety-doc-keeper': 'Safety doc keeper',
        'company__general--satety-doc-keeper-address': 'Safety doc keeper address',
        'company__general--satety-doc-keeper-signature': 'Safety doc signer',
        'company__general--construction-phase-deposit': 'Construction phase deposit',
        'company__general--incapability-deposit': 'Incapability deposit',
        'company__general--deposit-place-date': 'Place and date of deposit',
        'company__general--deposit-attachment': 'Has attached proof of possession',
        'company__general--built-date-earliest': 'Built date at the earliest',
        'company__general--built-date-latest': 'Built date at the latest',
        'company__general--built-date-estimated': 'Built date estimation',
        'company__general--building-permit-applied': 'Building permit applied',
        'company__general--building-permit-info': 'Building permit info',
        'company__general--contract-attachemnts': 'Titles of contract attachments',
        'company__general--contract-attachemnts-carslot': 'Titles of contract attachments (car slots)',
        'company__general--contract-attachemnts-office': 'Titles of contract attachments (offices)',
        'company__general--additional-terms': 'Additional terms',
        'company__general--additional-terms-carslot': 'Additional terms (car slots)',
        'company__general--additional-terms-office': 'Additional terms (offices)',
        'assignment__headings--contract-info': 'Contract',
        'assignment__headings--target-info': 'Target',
        'assignment__headings--housing': 'Housing',
        'assignment__headings--property': 'Property',
        'assignment__headings--building': 'Building',
        'assignment__headings--marketing': 'Marketing',
        'assignment__headings--transaction': 'Transaction',
        'assignment__headings--contractforms': 'Forms',
        'assignment__headings--tradebids': 'Tradebids',
        'assignment__headings--archive': 'Archive',
        'assignment__headings--buy-offers': 'Buy offers',
        'assignment__headings--print-diary': 'Print diary',
        'assignment__headings--flat-info': 'Apartment information',
        'assignment__headings--pricing-fees': 'Pricing and costs',
        'assignment__headings--other-info': 'Other info',
        'assignment__headings--energy-classification': 'Energy classification',
        'assignment__headings--internet': 'Internet',
        'assignment__headings--multimedia-links': 'Media links',
        'assignment__headings--photos': 'Photos',
        'assignment__headings--contract-basics': 'Contract basics',
        'assignment__headings--responsible-persons': 'Responsible persons',
        'assignment__headings--bids': 'Bids',
        'assignment__headings--sales-info': 'Sales info',
        'assignment__headings--sales-tax': 'Sales tax announcement',
        'assignment__headings--sales-tax-sent': 'Sales tax announcement sent',
        'assignment__headings--fee-division': 'Fee division',
        'assignment__headings--select-tax-receiver': 'Select tax receiver',
        'assignment__headings--space-n-storage': 'Space and storage',
        'assignment__headings--levels-n-materials': 'Levels and materials',
        'assignment__headings--dishwashers': 'Dishwashing',
        'assignment__headings--stoves-n-ovens': 'Stoves and ovens',
        'assignment__headings--refridgeration': 'Refridgeration',
        'assignment__headings--sauna': 'Sauna',
        'assignment__headings--showers': 'Showers',
        'assignment__headings--baths': 'Baths',
        'assignment__headings--machinery': 'Machinery and HVAC',
        'assignment__headings--others': 'Others',
        'assignment__headings--set-position': 'Set position',
        'assignment__headings--floor-material': 'Floor material',
        'assignment__label--internet-target-number': 'Target number',
        'assignment__label--assignment-number': 'Assignment number',
        'assignment__headings--extend-contract-terms': 'Extended contract terms',
        'assignment__label--number': 'Number',
        'assignment__label--name': 'Name',
        'assignment__label--address': 'Street address',
        'assignment__label--district': 'District',
        'assignment__label--country': 'Country',
        'assignment__label--region': 'Province',
        'assignment__label--coordinates': 'Coordinates',
        'assignment__label--room-count': 'Room count',
        'assignment__label--area-living': 'Living area',
        'assignment__label--area-additional': 'Additional area',
        'assignment__label--area-total': 'Total area',
        'assignment__label--area-extra-info': 'Area extra info',
        'assignment__label--target-features': 'Target features',
        'assignment__label--balcony-description': 'Balcony details',
        'assignment__label--other-details': 'Other details',
        'assignment__label--area-information': 'Area extra information',
        'assignment__label--balcony': 'Balcony',
        'assignment__label--terrace': 'Terrace',
        'assignment__label--sauna': 'Sauna',
        'assignment__label--lift': 'Elevator',
        'assignment__label--asbestos-survey': 'Asbestos survey',
        'assignment__label--asbestos-description': 'Asbestos survey results',
        'assignment__label--beach-type': 'Beach',
        'assignment__label--waterfront-type': 'Waterfront type',
        'assignment__label--driving-guide': 'Driving guide',
        'assignment__label--share-nos': 'Flat share numbers',
        'assignment__label--share-count': 'Flat share count',
        'assignment__label--share-registry': 'Electronic share registry',
        'assignment__label--flat-storey': 'Flat storey',
        'assignment__label--flat-number': 'Flat number',
        'assignment__label--flat-condition-classification': 'Condition classification',
        'assignment__label--flat-condition': 'Condition',
        'assignment__label--flat-kitchen-equipment': 'Kitchen equipment',
        'assignment__label--flat-kitchen': 'Kitchen description',
        'assignment__label--flat-wc': 'WC',
        'assignment__label--livingroom': 'Living room',
        'assignment__label--bedrooms': 'Bedrooms',
        'assignment__label--flat-bathrooms': 'Bathrooms',
        'assignment__label--flat-utility-room': 'Utility room',
        'assignment__label--flat-extra': 'Extra information',
        'assignment__label--flat-extra_brochure': 'Extra information (brochure only)',
        'assignment__label--foundation_type': 'Foundation type',
        'assignment__label--trade-bid-assignment': 'Trade bid assignment',
        'assignment__label--trade-bid-target-debtprice': 'Highest bid',
        'assignment__label--trade-bid-latest': 'Target debt price',
        'assignment__label--source-channel': 'Source channel',
        'assignment__label--contract-created': 'Contract created',
        'assignment__label--salesprice': 'Salesprice',
        'assignment__label--salesprice-start': 'Starting sales price',
        'assignment__label--debtprice-goal': 'Debt target price',
        'assignment__label--targetprice-mortgage-debt': 'Mortgage debt',
        'assignment__label--targetprice-mortgage-debt-free': 'Mortgage debt (free?)',
        'assignment__label--targetprice-mortgage-debt-keeping': 'Mortgage bond retainment',
        'assignment__label--targetprice-stress-proof-date': 'Encumbrance certificate date',
        'assignment__label--targetprice-property-tax': 'Property tax',
        'assignment__label--targetprice-water-total': 'Waterfee',
        'assignment__label--targetprice-cleaning': 'Cleaning costs',
        'assignment__label--targetprice-warming': 'Heating costs',
        'assignment__label--targetprices-total': 'Total costs',
        'assignment__label--targetprice-oil-use': 'Oil usage',
        'assignment__label--targetprice-electric-use': 'Electricity heating',
        'assignment__label--targetprice-electric-consumption': 'Electricity usage',
        'assignment__label--debtpart': 'Debt part',
        'assignment__label--debtprice-bid': 'Debt starting price',
        'assignment__label--debtprice': 'Debt price',
        'assignment__label--debtpart-date': 'Debt part reddemable',
        'assignment__label--debtprice-final': 'Final debt price',
        'assignment__label--targetprice-loanpayable': 'Debt is ',
        'assignment__label--targetprice-care': 'Care cost',
        'assignment__label--targetprice-care-sq': 'Care cost by square',
        'assignment__label--targetprice-financing': 'Financing cost',
        'assignment__label--targetprice-repair': 'Repair cost',
        'assignment__label--targetprice-financing-sq': 'Financing cost by square',
        'assignment__label--targetprice-repair-sq': 'Repair cost by square',
        'assignment__label--housingtotalfee': 'Total cost',
        'assignment__label--housingtotalfee-sq': 'Total cost by square',
        'assignment__label--targetprice-rent-per-month': 'Rent per month',
        'assignment__label--targetprice-other-payments': 'Other payments',
        'assignment__label--targetprice-other-buyers-payments': 'Other buyers payments',
        'assignment__label--targetprice-water': 'Water fee (per person)',
        'assignment__label--targetprice-waterspec': 'Water fee extra info',
        'assignment__label--target-freedate-type': 'Release date',
        'assignment__label--target-freedate': 'Date',
        'assignment__label--target-freedate-text': 'Release info',
        'assignment__label--target-lodger': 'Lodger',
        'assignment__label--target-lodger-phone': 'Lodger phone',
        'assignment__label--target-lodgerlivedsince': 'Lodger lived since',
        'assignment__label--target-revoking': 'Revoking',
        'assignment__label--target-revoking-date': 'Revoking date',
        'assignment__label--target-revoker': 'Revoker',
        'assignment__label--target-holding-type': 'Holding type',
        'assignment__label--targetprice-payment-method': 'Payment method',
        'assignment__label--target-burdens': 'Burdens/rights',
        'assignment__label--target-deed-restrictions': 'Deed restrictions',
        'assignment__label--certified-owner': 'Certified owner',
        'assignment__label--acquisition-type-date': 'Acquisition type and date',
        'assignment__label--target-modifications': 'Modifications',
        'assignment__label--target-extra': 'Extra information',
        'assignment__label--target-services': 'Area services',
        'assignment__label--target-schools': 'Schools and kindergartens',
        'assignment__label--target-transport': 'Transportation',
        'assignment__label--property-planning': 'Property planning',
        'assignment__label--property-infosource': 'Property info source',
        'assignment__label--planning-desc': 'Planning description',
        'assignment__label--target-class': 'Property class',
        'assignment__label--costlocation': 'Cost center',
        'assignment__label--target-type': 'Target type',
        'assignment__label--target-details': 'Target details',
        'assignment__label--energy-class': 'Energy class',
        'assignment__label--energy-certificate': 'Energy certificate',
        'assignment__label--energy-certificate-desc': 'Energy certificate description',
        'assignment__label--data-visibility': 'Visibility',
        'assignment__label--oikotie': 'Oikotie',
        'assignment__label--etuovi': 'Etuovi',
        'assignment__label--toimitilatfi': 'Toimitilat',
        'assignment__label--sales-brochure': 'Sales brochure',
        'assignment__label--window-display': 'Window display',
        'assignment__label--window-display-marketing': 'Window screen display',
        'assignment__label--website': 'Website',
        'assignment__label--heading': 'Heading',
        'assignment__label--internet-text': 'Description',
        'assignment__label--brochure-text': 'Brochure description',
        'assignment__label--windowcard-text': 'Windowcard description',
        'assignment__label--virtual-presentation': 'Virtual presentation link',
        'assignment__label--videoclip': 'Video clip link',
        'assignment__label--marketing-link': 'Marketing link',
        'assignment__label--construction-stage': 'Const.stage',
        'assignment__label--flat-count': 'Flats',
        'assignment__label--office': 'Office',
        'assignment__label--description': 'Description',
        'assignment__label--description-abr': 'Desc',
        'assignment__label--customer': 'Customer',
        'assignment__label--dealer': 'Dealer',
        'assignment__label--person': 'Supplier #1',
        'assignment__label--person2': 'Suplier #2',
        'assignment__label--person3': 'Handler',
        'assignment__label--person4': 'Salesman',
        'assignment__label--split1': 'Supplier #1 share',
        'assignment__label--split2': 'Supplier #2 share',
        'assignment__label--split3': 'Handler share',
        'assignment__label--split4': 'Salesman share',
        'assignment__label--validity': 'Validity',
        'assignment__label--contract-date': 'Contract date',
        'assignment__label--commission': 'Commission',
        'assignment__label--housing': 'Housing company',
        'assignment__label--target-new': 'New home',
        'assignment__label--target-holiday': 'Holiday home',
        'assignment__label--validity-start': 'Validity start',
        'assignment__label--validity-end': 'Validity end',
        'assignment__label--validity-continues': 'Validity continues 1mo at a time',
        'assignment__label--internal-memo': 'Internal memo',
        'assignment__label--buyer-customer': 'Buyer',
        'assignment__label--buyer-customer-2': 'Buyer #2',
        'assignment__label--diary-salesprice': 'Final salesprice',
        'assignment__label--deal-downpayment-amount': 'Downpayment',
        'assignment__label--deal-downpayment-date': 'Downpayment date',
        'assignment__label--deal-on-deal-amount': 'Paid on deal',
        'assignment__label--deal-date': 'Deal date',
        'assignment__label--deal-final-payment': 'Final payment',
        'assignment__label--deal-moving-date': 'Moving date',
        'assignment__label--deal-last-payment': 'Last payment terms',
        'assignment__label--deal-possession-transfers': 'Possession transfers',
        'assignment__label--deal-ownership-transfer-date': 'Ownership transfer date',
        'assignment__label--deed-edit': 'Edit contract',
        'assignment__label--deed-create': 'Create contract',
        'assignment__label--deal-type': 'Deal type',
        'assignment__label--cash-deal': 'Cash deal',
        'assignment__label--installment-payment': 'Installment payment',
        'assignment__label--ownership-transfers': 'Ownership transfers',
        'assignment__label--deal-fee-substract': 'Fee subastraction',
        'assignment__label--deal-fee-substract-reason': 'Fee subastraction reason',
        'assignment__label--flat-total-storeys': 'Total storyes',
        'assignment__label--property-usagetype': 'Usage type',
        'assignment__label--property-type': 'Property type',
        'assignment__label--property-city-type': 'Munipalicity or city',
        'assignment__label--unseparated-parcel': 'Unseparated piece of land',
        'assignment__label--property-identifier': 'Property identifier',
        'assignment__label--property-registration-date': 'Property registration date',
        'assignment__label--property-city': 'Munipalicity/city',
        'assignment__label--property-district': 'Village/district',
        'assignment__label--property-name': 'Property name',
        'assignment__label--property-reg-no': 'Registration number',
        'assignment__label--property-block': 'Block',
        'assignment__label--property-site-number': 'Site number',
        'assignment__label--property-rent-ends': 'Rent ends',
        'assignment__label--property-rent': 'Rent',
        'assignment__label--property-tenancy-handover': 'Rent handover',
        'assignment__label--property-landscape': 'Landscape',
        'assignment__label--property-soil': 'Soil',
        'assignment__label--property-limitation': 'Area limitations',
        'assignment__label--property-building-right': 'Building right',
        'assignment__label--property-public-utility': 'Public utility',
        'assignment__label--property-water': 'Water for consumption',
        'assignment__label--property-sewage': 'Sewage',
        'assignment__label--property-access': 'Plot access',
        'assignment__label--property-extra': 'Extra information',
        'assignment__label--tax-bailor': 'Tax bailor',
        'assignment__label--tax-receiver': 'Tax receiver',
        'assignment__label--tax-idcode': 'ID code/VAT',
        'assignment__label--tax-share': 'Share',
        'assignment__label--tax-first-time-buyer': 'First time buyer',
        'assignment__label--bank-transfer': 'Bank transfer',
        'assignment__label--tax-is-moving': 'Is moving',
        'assignment__label--tax-capital-transfer-relative': 'Transfer to relative',
        'assignment__label--tax-capital-transfer-dealer-trade': 'Dealer trade',
        'assignment__label--tax-capital-transfer-company-partner':
            'Was the acquirer a shareholder or a partner of conveyor company before the transaction or their parent, spouse or child',
        'assignment__label--tax-capital-transfer-seller-company-partner':
            'Is the seller a shareholder or a partner or acquirer shareholders or partners parent, spouse or child?',
        'assignment__label--tax-capital-transfer-internal-company-partner':
            'Is this an internal assignment of a combine or a company and it´s shareholder or partner?',
        'assignment__label--capital-transfer-tax-percent': 'Transfer tax percent',
        'assignment__label--capital-transfer-tax': 'Transfer tax',
        'assignment__label--capital-transfer-debtpart-tax': 'Transfer tax from debt part',
        'assignment__label--capital-transfer-payday': 'Due date',
        'assignment__label--capital-transfer-regular-flat-date': 'Regular resident from',
        'assignment__label--capital-transfer-flat-area': 'Part used as an apartment',
        'assignment__label--transfer-tax-form-date': 'Announcement date',
        'assignment__label--tax-company-form': 'Announced by our company',
        'assignment__label--tax-payable': 'Tax amount',
        'assignment__label--tax-paid-comfirmation': 'Buyers have paid capital transfer taxes',
        'assignment__commission--fee-type': 'Fee type',
        'assignment__commission--fee-type-percent': 'Percent',
        'assignment__commission--fee-type-fixed': 'Fixed minumum',
        'assignment__commission--fee-percent': 'Fee %',
        'assignment__commission--fee-incl-tax': 'Incl. tax 24%',
        'assignment__commission--fee-from-debt': 'Fee from debt price',
        'assignment__commission--min-fixed-fee': 'Min fixed fee',
        'assignment__commission--fee-substract': 'Fee substraction €',
        'assignment__commission--fee-substract-reason': 'Fee substraction reason',
        'assignment__commission--fee-with-tax': 'Fee incl. tax €',
        'assignment__commission--fee-with-no-tax': 'Fee excl. tax €',
        'assignment__commission--fee-payment-date': 'Fee payment date',
        'assignment__commission--fee-details': 'Fee details',
        'assignment__status--draft': 'Draft',
        'assignment__status--premarketing': 'Premark.',
        'assignment__status--on-sale': 'On sale',
        'assignment__status--free': 'Free',
        'assignment__status--sold': 'Sold',
        'assignment__target-type--flats': 'Flats',
        'assignment__target-type--offices': 'Offices',
        'assignment__target-type--storages': 'Storages',
        'assignment__target-type--carslots': 'Car slots',
        'assignment__target-type--block': 'Apartment build.',
        'assignment__target-type--rowhouse': 'Rowhouse',
        'assignment__target-type--semi': 'Semi-detached',
        'assignment__target-type--carslot': 'Car slot',
        'assignment__action--fill-persons': 'Prefill persons',
        'assignment__action--count-fee': 'Count fee',
        'assignment__action--add-new-housing': 'Add new housing company',
        'assignment__action--cancel-new-housing': 'Cancel new housing',
        'assignment__action--send-capital-transfer-form': 'Send capital transfer form',
        'assignment__action--do-capital-transfer-payment': 'Print capital transfer payments',
        'assignment__action--create-new': 'Create new assignment',
        'assignment__action--finalize-sale': 'Confirm sale',
        'assignment__action--invoice-fee': 'Create fee invoice',
        'assignment__action--view-ad': 'View ad',
        'assignment__action--status-incomplete-sold': 'Cancel deal',
        'assignment__action--status-incomplete-terminated': 'Restore assignment',
        'assignment__action--status-sale': 'Mark for sale',
        'assignment__action--status-reserved': 'Mark reserved',
        'assignment__action--status-sale-reserved': 'Return for sale',
        'assignment__action--status-terminated': 'Terminate assignment',
        'assignment__action--extend-contract': 'Extend contract',
        'assignment__action--duplicate': 'Copy as new',
        'assignment__error--fee-splits-mismatch': 'Fee split sum does not match 100%. Do you want to continue?',
        'housing__heading--contract-info': 'Contract info',
        'assignment__error--fee-data-missing': 'Fill in fee information',
        'assignment__error--seller-missing': 'Add seller',
        'housing__label--flat-reservations': 'Reserved / Sold / Total',
        'brochure__heading--settings': 'Brochure settings',
        'brochure__heading--create': 'Create a brochure',
        'brochure__label--printer': 'Person',
        'brochure__label--template': 'Brochure template',
        'brochure__label--compressible': 'Compressed layout',
        'brochure__text--print': 'Print',
        'brochure__text--page': 'Page',
        'brochure__text--info': 'Information',
        'brochure__text--infocon': 'Information (continued)',
        'brochure__text--floorplan': 'Floorplan',
        'brochure__text--footer': 'Footer',
        'brochure__text--logo': 'Logo',
        'brochure__text--main-image': 'Main image',
        'brochure__text--basic-info': 'Basic information',
        'brochure__text--description': 'Description',
        'brochure__text--target-image': 'Image',
        'brochure__select-option--empty-cover': 'empty cover letter',
        'brochure__select-option--photo': 'photo',
        'displaybrochure__label--demonstrator': 'Demonstrator',
        'displaybrochure__label--nodemonstrator': 'No demonstrator',
        'displaybrochure__heading--config': 'Displaycard config',
        'displaybrochure__heading--create': 'Create a display brochure',
        'pricelist__heading--settings': 'Pricelist config',
        'pricelist__heading--fields': 'Pricelist fields',
        'pricelist__heading--extra': 'Extra settings',
        'pricelist__heading--date': 'Pricelist date',
        'pricelist__settings--show-salesprices': 'Show sold salesprices',
        'pricelist__settings--show-salesprices-reserved': 'Show reserved salesprices',
        'pricelist__settings--hide-sold': 'Hide sold',
        'pricelist__settings--show-exact-status': 'Show exact status',
        'widgets__title--yield-multi': 'Outcome 6mo / €',
        'widgets__title--yield-volume': 'Outcome 6mo / volume',
        'widgets__title--yield-current': 'Outcome now',
        'widgets__title--news': 'Announcements',
        'widgets__title--leads': 'Leads',
        'widgets__title--assignments': 'Assignments',
        'widgets__title--customer-actions': 'New customer',
        'widget__lead--actions-interest': 'Interest',
        'widget__lead--actions-contact': 'Contact info',
        'widget__lead--actions-transfer': 'Transfer to user',
        'widget__lead--label-transfer': 'Transfer',
        'widget__lead--label-transfer-done': 'Transfered',
        'widget__yield--result-label': 'Percent from goal',
        'widget__yield--goal': 'Goal',
        'widget__yield--result': 'Outcome',
        'widget__yield--reserve': 'Reservations',
        'archive__heading--add-file': 'Add file',
        'archive__heading--digital-signing': 'Digital signage',
        'archive__heading--signers': 'Signers',
        'archive__heading--send-files-email': 'Send files by email',
        'archive__label--enable-inhouse': 'Use agents representative as a signer',
        'archive__label--signing-inhouse-person': 'Signing representative',
        'archive__label--add-signer': 'Add signer',
        'archive__label--signed-file': 'File to sign',
        'archive__label--inviter': 'Inviter',
        'archive__label--files-to-send': 'Files to be sent',
        'archive__label--sender-name': 'Sender name',
        'archive__label--sender-email': 'Sender email',
        'archive__label--receiver-email': 'Receiver email',
        'archive__label--message': 'Message',
        'archive__action--send-to-signing': 'Send to signing',
        'archive__action--cancel-signing': 'Cancel signing',
        'archive__action--signing-waiting-sending': 'waiting for sending',
        'archive__action--signing-waiting-signing': 'waiting for signing',
        'archive__action--signing-signed': 'signed',
        'archive__action--signing-canceled': 'canceled',
        'archive__action--given-information': 'Given password and address',
        'tooltip__assignment--asbestos-survey': 'TOOLTIP TBD',
        'tooltip__assignment--trade-bid-process': 'TOOLTIP TBD',
        'tooltip__assignment--condition-classification': 'TOOLTIP TBD',
        'tooltip__assignment--energy-certificate-desc': 'TOOLTIP TBD',
        'tooltip__assignment--save-before-transfer': 'Please save the document to access bank trasfer details',
        'tooltip__assignment--sales-tax': 'TOOLTIP TBD',
        'assignment__placeholder--tax-share': 'F.ex. 1/2',
    },
}
