import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import MainNavigation from './components/navigation/MainNavigation'
import { Fonts } from './styles/StyleConf'
import Config from './config/Config'
import { fetchRest, installationFromUrl, pdxLoginRedirect } from './utils/APIUtils'
import { SettingsContext } from './context/SettingsContext'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Error from './components/Error'

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            username: null,
            settings: null,
            error: null,
        }

        this.fetchSettings()

        this.fetchSettings = this.fetchSettings.bind(this)
    }

    fetchSettings() {
        const settingsData = fetchRest({ path: 'settings' }),
            userData = fetchRest({ path: 'account/info' }),
            paramCount = window.location.href.split('/').length - 3

        Promise.all([settingsData, userData]).then(res => {
            const [settings, user] = res
            if (
                settings &&
                settings.data &&
                settings.data.pdx &&
                settings.data.pdx.name &&
                settings.data.pdx.name.toLowerCase().trim() !==
                    installationFromUrl()
                        .toLowerCase()
                        .trim()
            ) {
                pdxLoginRedirect()
            } else if (
                settings &&
                settings.data &&
                settings.data.pdx &&
                settings.data.pdx.construction === '1' &&
                paramCount === 1
            ) {
                pdxLoginRedirect(false)
            } else if (settings.data.pdx.construction !== '1' && paramCount === 1) {
                window.location.href = window.location.href + '/assignments'
            } else if (settings && settings.data && user && user.data) {
                this.setState({ settings: { ...settings.data, user: user.data, error: null } })
            } else {
                this.setState({ error: 'connection' })
            }
        })
    }

    render() {
        return this.state.settings ? (
            <AppWrapper className="App">
                <SettingsContext.Provider value={this.state}>
                    <ThemeProvider theme={theme}>
                        <MainNavigation />
                    </ThemeProvider>
                </SettingsContext.Provider>
                <ModalRoot />
                <TooltipRoot />
                <ToastContainer
                    position={toast.POSITION.TOP_CENTER}
                    autoClose={false}
                    transition={Slide}
                    hideProgressBar={true}
                    closeButton={false}
                />
            </AppWrapper>
        ) : (
            this.state.error && <Error type={this.state.error} />
        )
    }
}

const AppWrapper = styled.div`
    & * {
        box-sizing: border-box;
    }

    font-family: ${Fonts.brandFont};
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    min-height: 300px;
`
const ModalRoot = styled.div.attrs({ id: Config.ui.modalId })``
const TooltipRoot = styled.div.attrs({ id: Config.ui.tooltipId })``

const bp = Config.breakpoints
const theme = {
    breakpoints: [`${bp.mobile}px`, `${bp.tablet}px`, `${bp.desktop}px`],
}

export default App
