import React from 'react'
import PropTypes from 'prop-types'

function ComponentLoader({ error, pastDelay }) {
    if (pastDelay) {
        return <div>Ladataan..</div>
    } else if (error) {
        return <div>Lataus epäonnistui - yritä uudelleen, kiitos</div>
    } else {
        return null
    }
}

ComponentLoader.propTypes = {
    error: PropTypes.bool,
    pastDelay: PropTypes.bool.isRequired,
}

export default ComponentLoader
