import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import trimStart from 'lodash/trimStart'
import { Link, withRouter } from 'react-router-dom'
import cx from 'classnames'
import Logo from '../ui/Logo'
import { UI, Colors } from '../../styles/StyleConf'
// import Search from '../input/Search'
import Navigation from '../ui/Navigation'
import Config from '../../config/Config'
import { H1 } from '../../styles/Headings'
import UserMenu from './UserMenu'
import MobileNavi from '../navigation/MobileNavi'
import { withUIState } from '../../context/UIContext'
import { withLocale } from '../../context/l10nContext'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import Svg from '../ui/Svg'
import { withSettings } from '../../context/SettingsContext'
import { getUserAccessLevels } from '../../utils/AccessUtils'

const Wrapper = styled.div`
    background-color: ${Colors.brandWhite};
    border-bottom: 1px solid ${Colors.uiGray};
    position: relative;
    margin-bottom: ${UI.headerHeightMobile / 5}px;
    height: ${UI.headerHeightMobile}px;
    padding-bottom: ${props => (props.fixed ? `${UI.headerHeightMobile * 0.8}px` : 0)};

    @media (min-width: ${Config.breakpoints.tablet}px) {
        height: ${UI.headerHeight}px;
        margin-bottom: 0;
    }
`
const Inner = styled.div`
    display: flex;
    align-self: center;
    background-color: ${Colors.brandWhite};
    width: ${UI.contentWidth}px;
    height: ${UI.headerHeightMobile}px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    position: ${props => (props.fixed ? 'fixed' : 'relative')};
    z-index: ${UI.zMobileNavi};

    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: block;
        padding: 20px;
    }
`
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`
const LargeLogo = styled(Logo)`
    && {
        display: none;

        @media (min-width: ${Config.breakpoints.tablet}px) {
            display: flex;
            margin-right: 4em;
        }
    }
`
const SmallLogo = styled(Logo).attrs({ size: 'small' })`
    && {
        @media (min-width: ${Config.breakpoints.tablet}px) {
            display: none;
        }
    }
`
// const StyledSearch = styled(Search)`
//     max-width: 50%;
//     margin: 0.5em 0.5em 0.5em 1em;

//     @media (min-width: ${Config.breakpoints.mobile}px) {
//         max-width: 40%;
//     }
// `
const NaviWrapper = styled.div`
    height: 40px;
    overflow-y: hidden;
    display: none;

    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: inline-block;
        overflow-y: visible;
    }
`
const Navi = styled(Navigation)`
    && {
        &:after {
            content: '';
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            width: 40px;
            height: 40px;
            position: absolute;
            right: 0;
        }

        display: none;

        @media (min-width: ${Config.breakpoints.tablet}px) {
            display: block;
            white-space: nowrap;
            height: 100px;
            padding-right: 80px;
        }
    }
`
const MenuBtn = styled(MobileNavi)`
    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: none;
    }
`
const Title = styled(H1)`
    position: absolute;
    bottom: -3em;
    left: 20px;

    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: none;
    }
`
const NaviItem = styled.li`
    position: relative;
`
const SubNavi = styled.ul`
    position: ${props => (props.mobile ? 'static' : 'absolute')};
    top: 2.4em;
    left: 0;
    z-index: ${UI.zMobileNavi};
    border-top: 1px solid ${Colors.uiGray};
    padding: 0;

    li {
        padding-left: ${props => (props.mobile ? '1em' : '0')};
    }
`
const SubNaviItem = styled.li`
    padding-right: 1em;
`
const arrowStyles = css`
    position: absolute;
    right: 1em;
    top: 1.4em;
`
const ArrowDown = styled(Svg).attrs({ type: 'angle-down' })`
    ${arrowStyles};
`
const ArrowUp = styled(Svg).attrs({ type: 'angle-up' })`
    ${arrowStyles};
`

const Header = ({
    location,
    locale,
    settings,
    userMenu,
    toggleUserMenu,
    mobileNavi,
    toggleMobileNavi,
    activeNavi,
    toggleActiveNavi,
    root,
}) => {
    const absolutePath = `/${trimStart(location.pathname, `/${locale}`)}`

    let data
    if (absolutePath.includes('/construction/')) {
        data = Config.navigation.construction
    } else {
        data = Config.navigation.default
    }

    const accessLevels = getUserAccessLevels(settings.user)

    const title = t(locale, data.filter(n => n.path === absolutePath)[0])
    const navigation = data
        .map(naviRow => {
            if (!accessLevels) return null
            if (naviRow.access && (!accessLevels.includes(naviRow.access) && !accessLevels.isAdmin)) return null

            const NaviLink = ({ path, pageId, title }) => {
                const txt = t(locale, title)
                return pageId ? (
                    <a href={`https://intra.pdx.fi/${settings.pdx.name}/intra/index.php?page_id=${pageId}`}>{txt}</a>
                ) : (
                    <Link to={`/${settings.pdx.name}${locale === 'fi' ? '' : '/' + locale}${path}`}>{txt}</Link>
                )
            }

            NaviLink.propTypes = {
                path: PropTypes.string,
                pageId: PropTypes.string,
                title: PropTypes.string.isRequired,
            }

            return (
                <NaviItem
                    className={cx('navigation__main--item', { active: root === naviRow.key })}
                    key={`navi_${naviRow.title}`}
                    onMouseEnter={() => {
                        !mobileNavi && naviRow.items && toggleActiveNavi(naviRow.key)
                    }}
                    onMouseLeave={() => {
                        !mobileNavi && naviRow.items && toggleActiveNavi(null)
                    }}
                    onClick={() => {
                        naviRow.items && toggleActiveNavi(activeNavi === naviRow.key ? null : naviRow.key)
                    }}
                >
                    {naviRow.items ? (
                        <>
                            <span>{t(locale, naviRow.title)}</span>
                            {mobileNavi && (activeNavi === naviRow.key ? <ArrowUp /> : <ArrowDown />)}
                        </>
                    ) : (
                        <NaviLink path={naviRow.path} pageId={naviRow.pageId} title={naviRow.title} />
                    )}
                    {activeNavi === naviRow.key && naviRow.items && (
                        <SubNavi mobile={mobileNavi}>
                            {naviRow.items
                                .map(item => {
                                    return item.access &&
                                        (!accessLevels.groups.includes(item.access) && !accessLevels.isAdmin) ? null : (
                                        <SubNaviItem
                                            key={item.key}
                                            className={cx('navigation__main--subitem', { active: root === item.key })}
                                        >
                                            <NaviLink path={item.path} pageId={item.pageId} title={item.title} />
                                        </SubNaviItem>
                                    )
                                })
                                .filter(n => n)}
                        </SubNavi>
                    )}
                </NaviItem>
            )
        })
        .filter(n => n)

    return (
        <Wrapper fixed={mobileNavi}>
            <Inner fixed={mobileNavi}>
                <Top>
                    {/* <Link to={`/${settings.pdx.name}`}>
                        <LargeLogo />
                        <SmallLogo />
                    </Link> */}
                    <a href={`https://intra.pdx.fi/${settings.pdx.name}/intra/index.php?page_id=102`}>
                        <LargeLogo />
                        <SmallLogo />
                    </a>
                    {/* <StyledSearch callback={res => console.info(`should search "${res}"`)} disabled={!auth.loggedIn} /> */}
                    <UserMenu
                        active={userMenu}
                        toggleMenu={() => toggleUserMenu()}
                        mobileNavi={mobileNavi}
                        username={settings.username}
                        disabled={false}
                    />
                    <MenuBtn active={mobileNavi} toggleMenu={() => toggleMobileNavi()}>
                        {navigation}
                    </MenuBtn>
                </Top>
                <NaviWrapper>
                    <Navi>{navigation}</Navi>
                </NaviWrapper>
            </Inner>
            {title && <Title>{title}</Title>}
        </Wrapper>
    )
}

Header.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    settings: PropTypes.shape({
        user: PropTypes.shape({}).isRequired,
        username: PropTypes.string,
        pdx: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    userMenu: PropTypes.bool.isRequired,
    toggleUserMenu: PropTypes.func.isRequired,
    mobileNavi: PropTypes.bool.isRequired,
    toggleMobileNavi: PropTypes.func.isRequired,
    activeNavi: PropTypes.string,
    toggleActiveNavi: PropTypes.func.isRequired,
    root: PropTypes.string,
}

export default withUIState(withRouter(withSettings(withLocale(Header))))
