import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, Fonts } from '../../styles/StyleConf'

const Notification = ({ label, className }) => {
    const Wrapper = styled.div`
        border-radius: 10px;
        color: ${Colors.brandWhite};
        background-color: ${Colors.brandBlue};
        position: absolute;
        top: -1em;
        right: -0.5em;
        width: 2em;
        padding: 5px;
        font-size: ${Fonts.xtraSmall};
        font-weight: bold;
        text-align: center;
    `

    return <Wrapper className={className}>{label}</Wrapper>
}

Notification.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string,
}

export default Notification
