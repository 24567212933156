import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import LoaderWrapper from '../LoaderWrapper'
import SiteWrapper from '../layout/SiteWrapper'
import { l10nContext } from '../../context/l10nContext'
import { withSettings } from '../../context/SettingsContext'

const localePrefix = '/:locale(fi|en)?',
    installation = '/:pdxname'

const PrivateRoute = ({ component: Component, locale, settings, path, exact, root, stripped, ...rest }) => (
    <Route
        path={path}
        exact={exact}
        render={props => {
            const superLocale = locale || props.match.params.locale

            return (
                <l10nContext.Provider value={superLocale || 'fi'}>
                    <SiteWrapper root={root} stripped={stripped}>
                        <Component {...Object.assign({}, rest, props)} />
                    </SiteWrapper>
                </l10nContext.Provider>
            )
        }}
    />
)

const LegacyRoute = ({ component: Component, locale, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <l10nContext.Provider value={locale || props.match.params.locale || 'fi'}>
                <Component {...props} />
            </l10nContext.Provider>
        )}
    />
)

const MainNavigation = ({ settings }) => {
    const Brochure = LoaderWrapper(() => import('../brochure/ConfigBrochure'))
    const Pricelist = LoaderWrapper(() => import('../pricelist/ConfigPricelist'))
    const DisplayBrochure = LoaderWrapper(() => import('../brochure/ConfigureDisplayBrochure'))
    const Dashboard = LoaderWrapper(() => import('../dashboard/DashboardGrid'))
    const AssignmentList = LoaderWrapper(() => import('../assignments/AssignmentList'))
    const Assignment = LoaderWrapper(() => import('../assignments/Assignment'))
    const HousingList = LoaderWrapper(() => import('../housing/HousingList'))

    const Construction = ({ match }) => (
        <React.Fragment>
            <PrivateRoute
                path={match.url + '/housing'}
                component={HousingList}
                locale={match.params.locale}
                settings={settings}
                exact
                root={`/housing`}
            />
            <PrivateRoute
                path={match.url + '/'}
                component={Dashboard}
                locale={match.params.locale}
                settings={settings}
                exact
            />
        </React.Fragment>
    )

    return (
        <Router>
            <React.Fragment>
                <LegacyRoute path={`${installation}${localePrefix}/brochure/:type/:id`} component={Brochure} />
                <LegacyRoute path={`${installation}${localePrefix}/pricelist/:id`} component={Pricelist} />
                <LegacyRoute
                    path={`${installation}${localePrefix}/displaybrochure/:type/:id`}
                    component={DisplayBrochure}
                />
                <PrivateRoute
                    path={`${installation}${localePrefix}/assignments`}
                    exact
                    component={AssignmentList}
                    type="assignment"
                    settings={settings}
                    root={`assignments`}
                />
                <PrivateRoute
                    path={`${installation}${localePrefix}/assignments/:id/:detail?`}
                    component={Assignment}
                    type="assignment"
                    settings={settings}
                    root={`assignments`}
                />
                <PrivateRoute
                    path={`${installation}${localePrefix}/rents`}
                    exact
                    component={AssignmentList}
                    type="rent"
                    settings={settings}
                    root={`rents`}
                />
                <PrivateRoute
                    path={`${installation}${localePrefix}/rents/:id`}
                    component={Assignment}
                    type="rent"
                    settings={settings}
                    root={`rents`}
                />
                <Route path={`${installation}${localePrefix}/construction/`} component={Construction} />
                <PrivateRoute exact path={`${installation}${localePrefix}`} component={Dashboard} root="dashboard" />
            </React.Fragment>
        </Router>
    )
}

export default withSettings(MainNavigation)
