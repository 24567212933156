import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Svg from './Svg'
import { Colors } from '../../styles/StyleConf'

const Logo = ({ type, size, className }) => {
    const proportions = {}
    switch (size) {
        case 'small':
            proportions.x = '70px'
            proportions.y = '40px'
            break
        default:
            proportions.x = '106px'
            proportions.y = '60px'
    }

    return (
        <Wrapper proportions={proportions} className={className}>
            <Img type="logo" size={size} inverse={type === 'inverse'} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: ${({ proportions }) => proportions.x};
    min-width: ${({ proportions }) => proportions.x};
    height: ${({ proportions }) => proportions.y};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Img = styled(Svg)`
    fill: ${({ inverse }) => (inverse ? Colors.brandWhite : Colors.brandBlue)};
`

Logo.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
}

export default Logo
