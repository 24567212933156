import React from 'react'
import PropTypes from 'prop-types'

const Svg = ({ type, size, className }) => {
    let path, sizes, transform

    const paths = {
        pdx:
            'M 0 15.4L 0 0L 6.622 0C 10.494 0 13.002 2.002 13.002 5.39L 13.002 5.434C 13.002 9.086 10.208 11 6.402 11L 4.268 11L 4.268 15.4L 0 15.4ZM 4.26794 7.656L 6.31394 7.656C 7.80994 7.656 8.75594 6.842 8.75594 5.632L 8.75594 5.588C 8.75594 4.268 7.80994 3.586 6.29194 3.586L 4.26794 3.586L 4.26794 7.656ZM 14.7274 15.4L 14.7274 0L 20.6894 0C 26.1674 0 29.3574 3.168 29.3574 7.612L 29.3574 7.656C 29.3574 12.1 26.1234 15.4 20.6014 15.4L 14.7274 15.4ZM 20.7335 3.76199L 18.9955 3.76199L 18.9955 11.616L 20.7335 11.616C 23.3075 11.616 25.0015 10.208 25.0015 7.72199L 25.0015 7.67799C 25.0015 5.21399 23.3075 3.76199 20.7335 3.76199ZM 39.7728 0L 44.5688 0L 39.5088 7.502L 44.7888 15.4L 39.8828 15.4L 36.9348 10.736L 33.9648 15.4L 29.1688 15.4L 34.4488 7.568L 29.3888 0L 34.2948 0L 37.0008 4.334L 39.7728 0ZM 50.0783 13.332L 50.0783 9.504L 46.2063 9.504L 46.2063 5.808L 50.0783 5.808L 50.0783 1.98L 53.9063 1.98L 53.9063 5.808L 57.7783 5.808L 57.7783 9.504L 53.9063 9.504L 53.9063 13.332L 50.0783 13.332Z',
        angle:
            'M 13.7 0.3C 13.3 -0.1 12.7 -0.1 12.3 0.3L 7 5.6L 1.7 0.3C 1.3 -0.1 0.7 -0.1 0.3 0.3C -0.1 0.7 -0.1 1.3 0.3 1.7L 6.3 7.7C 6.5 7.9 6.8 8 7 8C 7.2 8 7.5 7.9 7.7 7.7L 13.7 1.7C 14.1 1.3 14.1 0.7 13.7 0.3Z',
        calendar: [
            'M16.8095238,16.9880952 C16.8095238,17.1392381 16.6863095,17.2619048 16.5357143,17.2619048 L3.01190476,17.2619048 C2.86130952,17.2619048 2.73809524,17.1392381 2.73809524,16.9880952 L2.73809524,4.6547619 C2.73809524,4.50361905 2.86130952,4.38095238 3.01190476,4.38095238 L6.02380952,4.38095238 L6.02380952,5.20238095 C6.02380952,5.95864286 6.63659524,6.57142857 7.39285714,6.57142857 C8.14911905,6.57142857 8.76190476,5.95864286 8.76190476,5.20238095 L8.76190476,4.38095238 L10.4047619,4.38095238 L10.4047619,5.20238095 C10.4047619,5.95864286 11.0175476,6.57142857 11.7738095,6.57142857 C12.5300714,6.57142857 13.1428571,5.95864286 13.1428571,5.20238095 L13.1428571,4.38095238 L16.5357143,4.38095238 C16.6863095,4.38095238 16.8095238,4.50361905 16.8095238,4.6547619 L16.8095238,16.9880952 Z M13.1428571,1.64285714 L13.1428571,1.36904762 C13.1428571,0.612785714 12.5300714,0 11.7738095,0 C11.0175476,0 10.4047619,0.612785714 10.4047619,1.36904762 L10.4047619,1.64285714 L8.76190476,1.64285714 L8.76190476,1.36904762 C8.76190476,0.612785714 8.14911905,0 7.39285714,0 C6.63659524,0 6.02380952,0.612785714 6.02380952,1.36904762 L6.02380952,1.64285714 L3.01190476,1.64285714 C1.35152381,1.64285714 0,2.99383333 0,4.6547619 L0,16.9880952 C0,18.6490238 1.35152381,20 3.01190476,20 L16.5357143,20 C18.1960952,20 19.547619,18.6490238 19.547619,16.9880952 L19.547619,4.6547619 C19.547619,2.99383333 18.1960952,1.64285714 16.5357143,1.64285714 L13.1428571,1.64285714 Z',
            'M12.3214286,8.21428571 L11.2261905,8.21428571 C10.4699286,8.21428571 9.85714286,8.82707143 9.85714286,9.58333333 C9.85714286,10.3395952 10.4699286,10.952381 11.2261905,10.952381 L12.3214286,10.952381 C13.0776905,10.952381 13.6904762,10.3395952 13.6904762,9.58333333 C13.6904762,8.82707143 13.0776905,8.21428571 12.3214286,8.21428571',
            'M7.39285714,8.2 L6.29761905,8.2 C5.54135714,8.2 4.92857143,8.81278571 4.92857143,9.56904762 C4.92857143,10.3253095 5.54135714,10.9380952 6.29761905,10.9380952 L7.39285714,10.9380952 C8.14911905,10.9380952 8.76190476,10.3253095 8.76190476,9.56904762 C8.76190476,8.81278571 8.14911905,8.2 7.39285714,8.2',
            'M7.46428571,12.047619 L6.36904762,12.047619 C5.61278571,12.047619 5,12.6604048 5,13.4166667 C5,14.1729286 5.61278571,14.7857143 6.36904762,14.7857143 L7.46428571,14.7857143 C8.22054762,14.7857143 8.83333333,14.1729286 8.83333333,13.4166667 C8.83333333,12.6604048 8.22054762,12.047619 7.46428571,12.047619',
        ],
        locationPin: [
            'M 3 5.75C 3 7.26877 4.2312 8.5 5.75 8.5C 6.47925 8.5 7.17871 8.21027 7.69458 7.69455C 8.21021 7.17883 8.5 6.47934 8.5 5.75C 8.5 4.23123 7.2688 3 5.75 3C 4.2312 3 3 4.23123 3 5.75ZM 4.5 5.75C 4.5 5.05963 5.05957 4.5 5.75 4.5C 6.44043 4.5 7 5.05963 7 5.75C 7 6.44037 6.44043 7 5.75 7C 5.05957 7 4.5 6.44037 4.5 5.75Z',
            'M 5.26001 15.59L 5.75 16L 6.23999 15.57C 6.5 15.39 11.5 11.04 11.5 5.75C 11.5 2.57437 8.92554 0 5.75 0C 2.57446 0 0 2.57437 0 5.75C 0 10.7956 4.66431 15.0468 5.21411 15.5478L 5.26001 15.59ZM 1.5 5.75C 1.5 3.4028 3.40283 1.5 5.75 1.5C 8.09717 1.5 10 3.4028 10 5.75C 10 9.45001 6.95996 12.8 5.75 13.98C 4.5 12.75 1.5 9.44 1.5 5.75Z',
        ],
        close: [
            'M 14.4186 15.6186C 14.1004 15.6183 13.7954 15.4917 13.5706 15.2666L 0.354567 2.06657C -0.118189 1.59381 -0.118189 0.827323 0.354567 0.354567C 0.827323 -0.118189 1.59381 -0.118189 2.06657 0.354567L 15.2666 13.5546C 15.6093 13.8977 15.7118 14.4135 15.5262 14.8616C 15.3407 15.3098 14.9036 15.6021 14.4186 15.6026L 14.4186 15.6186Z',
            'M 1.19898 15.5701C 0.713953 15.5696 0.276874 15.2773 0.0913203 14.8291C -0.0942337 14.381 0.00822693 13.8652 0.350979 13.5221L 13.551 0.322068C 14.0238 -0.118506 14.7606 -0.105506 15.2176 0.351473C 15.6746 0.808452 15.6876 1.54525 15.247 2.01807L 2.04698 15.2181C 1.82215 15.4432 1.51713 15.5698 1.19898 15.5701Z',
        ],
        close2:
            'M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z',
        plus:
            'M 12.0556 5.44444L 7.77778 5.44444L 7.77778 1.16667C 7.77778 0.544444 7.23333 0 6.61111 0C 5.98889 0 5.44444 0.544444 5.44444 1.16667L 5.44444 5.44444L 1.16667 5.44444C 0.544444 5.44444 0 5.98889 0 6.61111C 0 7.23333 0.544444 7.77778 1.16667 7.77778L 5.44444 7.77778L 5.44444 12.0556C 5.44444 12.6778 5.98889 13.2222 6.61111 13.2222C 7.23333 13.2222 7.77778 12.6778 7.77778 12.0556L 7.77778 7.77778L 12.0556 7.77778C 12.6778 7.77778 13.2222 7.23333 13.2222 6.61111C 13.2222 5.98889 12.6778 5.44444 12.0556 5.44444Z',
        minus:
            'M 12.0556 0L 7.77778 0L 5.44444 0L 1.16667 0C 0.544444 0 0 0.544444 0 1.16667C 0 1.78889 0.544444 2.33333 1.16667 2.33333L 5.44444 2.33333L 7.77778 2.33333L 12.0556 2.33333C 12.6778 2.33333 13.2222 1.78889 13.2222 1.16667C 13.2222 0.544444 12.6778 0 12.0556 0Z',
        magnifier:
            'M 13.7505 12.225L 18.4116 16.725C 18.7224 17.025 18.7224 17.475 18.4116 17.775C 18.2563 17.925 18.0232 18 17.8679 18C 17.7126 18 17.4795 17.925 17.3242 17.775L 12.6628 13.275C 11.3423 14.4 9.63306 15 7.76855 15C 3.49585 15 0 11.625 0 7.5C 0 3.375 3.49585 0 7.76855 0C 12.0415 0 15.5374 3.375 15.5374 7.5C 15.5374 9.3 14.8381 10.95 13.7505 12.225ZM 7.76855 1.5C 4.35034 1.5 1.55371 4.2 1.55371 7.5C 1.55371 10.8 4.35034 13.5 7.76855 13.5C 11.1868 13.5 13.9836 10.8 13.9836 7.5C 13.9836 4.2 11.1868 1.5 7.76855 1.5Z',
        list: [
            'M 18.25 1.75L 3.75 1.75C 3.33579 1.75 3 1.41421 3 1C 3 0.585786 3.33579 0.25 3.75 0.25L 18.25 0.25C 18.6642 0.25 19 0.585786 19 1C 19 1.41421 18.6642 1.75 18.25 1.75Z',
            'M 18.25 6.75L 3.75 6.75C 3.33579 6.75 3 6.41421 3 6C 3 5.58579 3.33579 5.25 3.75 5.25L 18.25 5.25C 18.6642 5.25 19 5.58579 19 6C 19 6.41421 18.6642 6.75 18.25 6.75Z',
            'M 18.25 11.75L 3.75 11.75C 3.33579 11.75 3 11.4142 3 11C 3 10.5858 3.33579 10.25 3.75 10.25L 18.25 10.25C 18.6642 10.25 19 10.5858 19 11C 19 11.4142 18.6642 11.75 18.25 11.75Z',
            'M 1 2C 1.55228 2 2 1.55228 2 1C 2 0.447715 1.55228 0 1 0C 0.447715 0 0 0.447715 0 1C 0 1.55228 0.447715 2 1 2Z',
            'M 1 7C 1.55228 7 2 6.55228 2 6C 2 5.44772 1.55228 5 1 5C 0.447715 5 0 5.44772 0 6C 0 6.55228 0.447715 7 1 7Z',
            'M 1 12C 1.55228 12 2 11.5523 2 11C 2 10.4477 1.55228 10 1 10C 0.447715 10 0 10.4477 0 11C 0 11.5523 0.447715 12 1 12Z',
        ],
        menu: [
            'M 15.25 1.5L 0.75 1.5C 0.335786 1.5 5.07265e-17 1.16421 0 0.75C -5.07265e-17 0.335786 0.335786 0 0.75 0L 15.25 0C 15.6642 0 16 0.335786 16 0.75C 16 1.16421 15.6642 1.5 15.25 1.5Z',
            'M 15.25 6.5L 0.75 6.5C 0.335786 6.5 5.07265e-17 6.16421 0 5.75C -5.07265e-17 5.33579 0.335786 5 0.75 5L 15.25 5C 15.6642 5 16 5.33579 16 5.75C 16 6.16421 15.6642 6.5 15.25 6.5Z',
            'M 15.25 11.5L 0.75 11.5C 0.335786 11.5 5.07265e-17 11.1642 0 10.75C -5.07265e-17 10.3358 0.335786 10 0.75 10L 15.25 10C 15.6642 10 16 10.3358 16 10.75C 16 11.1642 15.6642 11.5 15.25 11.5Z',
        ],
        checkmark:
            'M 5.355 10.6358C 5.075 10.6358 4.795 10.4958 4.585 10.3558L 0.315 6.08578C -0.105 5.66578 -0.105 4.96578 0.315 4.61578C 0.735 4.19578 1.435 4.19578 1.785 4.61578L 5.215 8.04578L 11.375 0.41578C 11.725 -0.0742201 12.425 -0.14422 12.845 0.27578C 13.335 0.62578 13.405 1.32578 12.985 1.74578L 6.195 10.2158C 5.985 10.4958 5.705 10.6358 5.355 10.6358C 5.355 10.6358 5.425 10.6358 5.355 10.6358Z',
        exclamationmark:
            'M 0.140015 0L 2.85999 0L 2.85999 6.89L 0.140015 6.89L 0.140015 0ZM 0 9.23C 0 8.40157 0.671631 7.73 1.5 7.73C 2.32837 7.73 3 8.40157 3 9.23C 3 10.0584 2.32837 10.73 1.5 10.73C 0.671631 10.73 0 10.0584 0 9.23Z',
        move:
            'M15.5061 31.0122L19.8362 23.5122H11.176L15.5061 31.0122ZM15.5061 0L11.176 7.5H19.8362L15.5061 0ZM0 15.5061L7.5 19.8362V11.176L0 15.5061ZM31.0122 15.5061L23.5122 11.176V19.8362L31.0122 15.5061ZM16.2561 24.2622V15.5061H14.7561V24.2622H16.2561ZM16.2561 15.5061V6.75H14.7561V15.5061H16.2561ZM6.75 16.2561H15.5061V14.7561H6.75V16.2561ZM15.5061 16.2561H24.2622V14.7561H15.5061V16.2561Z',
        trash:
            'M14,6 L14,17 C14,18.6568542 12.6568542,20 11,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,6 L14,6 Z M4,8 L2,8 L2,17 C2,17.5522847 2.44771525,18 3,18 L4,18 L4,8 Z M6,8 L6,18 L8,18 L8,8 L6,8 Z M8,0 C8.55228475,-1.01453063e-16 9,0.44771525 9,1 L9,2 L13,2 C13.5522847,2 14,2.44771525 14,3 C14,3.55228475 13.5522847,4 13,4 L1,4 C0.44771525,4 6.76353751e-17,3.55228475 0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 L5,2 L5,1 C5,0.44771525 5.44771525,1.01453063e-16 6,0 L8,0 Z M10,18 L11,18 C11.5522847,18 12,17.5522847 12,17 L12,8 L10,8 L10,18 Z',
        user:
            'M27.3137 20.6863C25.5711 18.9436 23.4968 17.6536 21.2337 16.8726C23.6576 15.2032 25.25 12.4093 25.25 9.25C25.25 4.14956 21.1004 0 16 0C10.8996 0 6.75 4.14956 6.75 9.25C6.75 12.4093 8.34244 15.2032 10.7664 16.8726C8.50325 17.6536 6.429 18.9436 4.68631 20.6863C1.66431 23.7083 0 27.7263 0 32H2.5C2.5 24.5561 8.55606 18.5 16 18.5C23.4439 18.5 29.5 24.5561 29.5 32H32C32 27.7263 30.3357 23.7083 27.3137 20.6863ZM16 16C12.2781 16 9.25 12.972 9.25 9.25C9.25 5.528 12.2781 2.5 16 2.5C19.7219 2.5 22.75 5.528 22.75 9.25C22.75 12.972 19.7219 16 16 16Z',
        download:
            'M17,17 C17.5522847,17 18,17.4477153 18,18 C18,18.5522847 17.5522847,19 17,19 L1,19 C0.44771525,19 6.76353751e-17,18.5522847 0,18 C-6.76353751e-17,17.4477153 0.44771525,17 1,17 L17,17 Z M8,1 C8,0.44771525 8.44771525,1.01453063e-16 9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 L10,6 L15.3710982,6 C15.6283826,6 15.8757678,6.09916494 16.0618164,6.27687595 C16.4611868,6.65834907 16.4756954,7.2913478 16.0942222,7.69071818 L16.0942222,7.69071818 L9.82095126,14.2583071 C9.81511421,14.264418 9.80919993,14.2704547 9.80320986,14.2764157 C9.41173567,14.6659877 8.77857257,14.6644458 8.38900049,14.2729716 L8.38900049,14.2729716 L1.85332238,7.70538265 C1.66683846,7.51798822 1.5621491,7.2643727 1.5621491,7 C1.5621491,6.44771525 2.00986435,6 2.5621491,6 L2.5621491,6 L8,6 L8,1 Z',
        pen:
            'M0.951114 32C0.753388 32 0.567775 31.9445 0.409593 31.8343C0.117101 31.6295 -0.0303923 31.2736 0.00523404 30.8572L0.603045 30.9077L0.00559031 30.8558L0.441301 25.8382C0.514691 24.987 0.957527 23.7714 1.44846 23.0716L16.3866 1.77986C17.7108 -0.107074 20.3258 -0.566808 22.2169 0.753727C24.1072 2.07497 24.5682 4.6844 23.2443 6.57134L8.30654 27.8628C7.81489 28.5628 6.82234 29.3938 6.04569 29.7536L1.47055 31.8788L1.22544 31.354L1.46912 31.8795C1.2942 31.9602 1.11856 32 0.951114 32ZM21.7626 1.52286C20.4493 0.606538 18.6336 0.926184 17.7149 2.23453L16 4.67703L20.7601 8L22.4753 5.55851C23.3944 4.24949 23.0745 2.43918 21.7626 1.52286ZM14.8777 6L20 9.49012L8.12227 26L3 22.5092L14.8777 6ZM2.32715 23.3756C1.92318 23.9506 1.51498 25.0683 1.45446 25.7681L1 31L5.77819 28.7842C6.41729 28.4877 7.33199 27.7238 7.73634 27.1488L8 26.7736L2.59157 23L2.32715 23.3756Z',
        logo: [
            'M217.5,5c-4.1,0-7.5,3.4-7.5,7.5v46.8c-9-8.8-21.4-14.3-35-14.3c-27.6,0-50,22.4-50,50s22.4,50,50,50s50-22.4,50-50V12.5C225,8.4,221.6,5,217.5,5z M175,130c-19.3,0-35-15.7-35-35s15.7-35,35-35s35,15.7,35,35S194.3,130,175,130',
            'M60,45c-27.6,0-50,22.4-50,50v82.5c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-46.8c9,8.8,21.4,14.3,35,14.3c27.6,0,50-22.4,50-50S87.6,45,60,45z M60,130c-19.3,0-35-15.7-35-35s15.7-35,35-35c19.3,0,35,15.7,35,35S79.3,130,60,130',
            'M297.9,93.3l35.4-35.4c2.9-2.9,2.9-7.7,0-10.6s-7.7-2.9-10.6,0l-35.4,35.4l-35.4-35.4c-2.9-2.9-7.7-2.9-10.6,0c-2.9,2.9-2.9,7.7,0,10.6l35.4,35.4l-35.4,35.4c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0l35.4-35.4l35.4,35.4c2.9,2.9,7.7,2.9,10.6,0s2.9-7.7,0-10.6L297.9,93.3',
        ],
        pin:
            'M16 4.087c-4.583 0-8.174 3.777-8.174 8.598 0 1.554 0.418 3.075 1.209 4.4 0.283 0.421 3.947 5.365 6.979 9.446 2.981-4.023 6.579-8.895 6.959-9.461 0.786-1.32 1.2-2.836 1.2-4.385 0-4.821-3.59-8.598-8.174-8.598v0zM16.016 32c-0.646 0-1.254-0.305-1.64-0.824-3.895-5.233-8.592-11.563-8.852-11.997-1.168-1.957-1.786-4.203-1.786-6.495 0-7.113 5.385-12.684 12.261-12.684s12.261 5.572 12.261 12.684c0 2.3-0.621 4.552-1.798 6.513-0.311 0.516-5.65 7.724-8.806 11.977-0.386 0.519-0.993 0.826-1.64 0.826h-0.001zM16 16.32c-1.076 0-2.132-0.436-2.888-1.199-0.191-0.184-0.362-0.395-0.511-0.62s-0.273-0.456-0.375-0.708c-0.102-0.245-0.185-0.504-0.232-0.763-0.055-0.259-0.082-0.531-0.082-0.797s0.027-0.538 0.082-0.797c0.047-0.259 0.129-0.518 0.232-0.763 0.101-0.252 0.225-0.484 0.375-0.708s0.319-0.436 0.511-0.62c0.946-0.954 2.357-1.39 3.685-1.117 0.266 0.048 0.518 0.129 0.763 0.232 0.252 0.102 0.49 0.225 0.708 0.375 0.225 0.15 0.436 0.32 0.62 0.511 0.191 0.184 0.361 0.395 0.511 0.62 0.143 0.225 0.272 0.456 0.375 0.708 0.102 0.245 0.184 0.504 0.232 0.763 0.055 0.259 0.082 0.531 0.082 0.797s-0.027 0.538-0.082 0.797c-0.048 0.259-0.13 0.518-0.232 0.763-0.102 0.252-0.232 0.484-0.375 0.708-0.15 0.225-0.32 0.436-0.511 0.62-0.184 0.191-0.395 0.361-0.62 0.511-0.218 0.15-0.456 0.272-0.708 0.375-0.245 0.102-0.497 0.184-0.763 0.232-0.266 0.055-0.531 0.082-0.797 0.082z',
        uploadCloud:
            'M24.275 11.396c-0.4 0-0.934 0-1.335 0.133-1.068-3.47-4.271-5.872-8.141-5.872-4.671 0-8.542 3.737-8.542 8.275 0 0.4 0 0.801 0.133 1.201-0.267 0-0.4 0-0.667 0-3.203 0-5.739 2.536-5.739 5.605s2.536 5.605 5.739 5.605h7.074v-3.737h-4.004l7.207-8.008 7.207 8.008h-4.004v3.737h5.205c4.271 0 7.607-3.337 7.607-7.474-0.133-4.137-3.604-7.474-7.741-7.474z',
        lightning:
            'M10.1921637,0.278584302 L0.361845164,9.08863833 C-0.0494385284,9.4572359 -0.0840424665,10.0894546 0.284555105,10.5007383 C0.474242944,10.7123933 0.745033092,10.8333333 1.02925011,10.8333333 L7.39426609,10.8333333 C7.94655084,10.8333333 8.39426609,11.2810486 8.39426609,11.8333333 C8.39426609,12.0522585 8.32242332,12.2651347 8.18976434,12.4392892 L2.90777653,19.3734773 C2.7772174,19.5448752 2.81032375,19.7896597 2.98172165,19.9202189 C3.04966361,19.9719724 3.13271182,20 3.21811988,20 L3.60361686,20 C3.8616374,20 4.10967192,19.9002682 4.29587294,19.7216519 L14.1992796,10.2216519 C14.5978369,9.83932947 14.6109979,9.20630128 14.2286754,8.80774392 C14.0400859,8.6111462 13.7794508,8.5 13.5070235,8.5 L7.01326461,8.5 C6.46097986,8.5 6.01326461,8.05228475 6.01326461,7.5 C6.01326461,7.27413062 6.08973052,7.05491376 6.23021091,6.87804591 L11.3112429,0.480916361 C11.4095419,0.357155749 11.3889012,0.177140843 11.2651406,0.0788418134 C11.212413,0.0369619894 11.1466062,0.0150165331 11.0792957,0.0168656846 L10.8321071,0.0236564433 C10.595333,0.0301610932 10.3685547,0.120500499 10.1921637,0.278584302 Z',
        email:
            'M24.5735886,14.1434322 L32.34401,18.2898366 C32.748233,18.5047467 33,18.9285821 33,19.3905027 L33,29.7562419 C33,30.4434101 32.4502997,31 31.7728371,31 L16.2271629,31 C15.5491635,31 15,30.442866 15,29.7562419 L15,19.3905027 C15,18.9155242 15.2630402,18.5025704 15.6495482,18.2931011 L15.65599,18.2898366 L23.4312427,14.1434322 C23.7887626,13.9519173 24.2166055,13.9524614 24.5735886,14.1434322 Z M24,16.3 L17,20.1936522 L17,29 L31,29 L31,20.1930799 L24,16.3 Z M20.1546225,21 C20.4024021,21 20.6315099,21.0739602 20.8197416,21.2007492 L24.0005046,23.3211027 L27.1848002,21.1997887 C27.3689948,21.0758813 27.5986072,21.0014408 27.8463868,21.0014408 C28.4837505,21.0014408 29,21.4932283 29,22.099318 C29,22.4700797 28.8072265,22.7976179 28.5115059,22.9964461 L24.0005046,26 L19.4930359,22.9993276 C19.1932782,22.7980982 19,22.4700797 19,22.0988378 C19,21.4922678 19.5167541,21 20.1546225,21 Z',
        info:
            'M8,6 L8,18 L9,18 C9.55228475,18 10,18.4477153 10,19 C10,19.5522847 9.55228475,20 9,20 L1,20 C0.44771525,20 6.76353751e-17,19.5522847 0,19 C-6.76353751e-17,18.4477153 0.44771525,18 1,18 L4,18 L4,8 L1,8 C0.44771525,8 6.76353751e-17,7.55228475 0,7 C-6.76353751e-17,6.44771525 0.44771525,6 1,6 L8,6 Z M6,0 C7.1045695,0 8,0.8954305 8,2 C8,3.1045695 7.1045695,4 6,4 C4.8954305,4 4,3.1045695 4,2 C4,0.8954305 4.8954305,0 6,0 ',
        preview:
            'M15.5250978,1.73469498 C16.0333967,2.13765524 16.4908012,2.60093921 16.8872368,3.11434315 L21,8.44057649 L17.3128114,14.2768013 C14.952955,18.0120735 10.011877,19.1270682 6.27660484,16.7672117 C5.29903936,16.149609 4.46697609,15.3274298 3.8377361,14.3573141 L0.431896003,9.1064418 L0,8.44057649 L4.28623054,3.033873 C7.03099992,-0.428412592 12.0628122,-1.0100744 15.5250978,1.73469498 Z M5.85348619,4.27633415 L2.45963456,8.55738 L5.5156778,13.2689614 C5.9876078,13.9965481 6.61165525,14.6131826 7.34482936,15.0763846 C10.1462835,16.846277 13.852092,16.0100309 15.6219843,13.2085768 L18.5617772,8.55535871 L15.3042409,4.33668676 C15.0069142,3.95163381 14.6638609,3.60417083 14.2826366,3.30195064 C11.6859224,1.2433736 7.91206323,1.67961996 5.85348619,4.27633415 Z M10.5,4 C12.9852814,4 15,5.790861 15,8 C15,10.209139 12.9852814,12 10.5,12 C8.01471863,12 6,10.209139 6,8 C6,5.790861 8.01471863,4 10.5,4 Z M9.5,5 C8.67157288,5 8,5.67157288 8,6.5 C8,7.32842712 8.67157288,8 9.5,8 C10.3284271,8 11,7.32842712 11,6.5 C11,5.67157288 10.3284271,5 9.5,5 Z',
        envelope:
            'M9.57359 0.143432L17.344 4.28984C17.7482 4.50475 18 4.92858 18 5.3905V15.7562C18 16.4434 17.4503 17 16.7728 17H1.22716C0.549164 17 0 16.4429 0 15.7562V5.3905C0 4.91552 0.263041 4.50257 0.649549 4.2931L0.65599 4.28984L8.43124 0.143432C8.78876 -0.0480827 9.21661 -0.0475386 9.57359 0.143432ZM9 2.3L2 6.19365V15H16V6.19308L9 2.3ZM5.15462 7C5.4024 7 5.63151 7.07396 5.81974 7.20075L9.00051 9.3211L12.1848 7.19979C12.369 7.07588 12.5986 7.00144 12.8464 7.00144C13.4837 7.00144 14 7.49323 14 8.09932C14 8.47008 13.8072 8.79762 13.5115 8.99645L9.00051 12L4.49304 8.99933C4.19328 8.7981 4 8.47008 4 8.09884C4 7.49227 4.51675 7 5.15462 7Z',
    }

    switch (type) {
        case 'pdx':
            path = paths.pdx
            sizes = {
                default: { x: 59, y: 16 },
                small: { x: 38, y: 10 },
            }
            break
        case 'angle-down':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 }, small: { x: 11, y: 6 } }
            break
        case 'angle-left':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 } }
            transform = 'rotate(90)'
            break
        case 'angle-up':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 }, small: { x: 11, y: 6 } }
            transform = 'rotate(180)'
            break
        case 'angle-right':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 } }
            transform = 'rotate(270)'
            break
        case 'calendar':
            path = paths.calendar
            sizes = { default: { x: 20, y: 20 } }
            break
        case 'location-pin':
            path = paths.locationPin
            sizes = { default: { x: 12, y: 16 } }
            break
        case 'close':
            path = paths.close
            sizes = { default: { x: 16, y: 16 }, small: { x: 10, y: 10 } }
            break
        case 'close2':
            path = paths.close2
            sizes = { default: { x: 20, y: 20 } }
            break
        case 'plus':
            path = paths.plus
            sizes = { default: { x: 14, y: 14 } }
            break
        case 'minus':
            path = paths.minus
            sizes = { default: { x: 14, y: 14 } }
            break
        case 'magnifier':
            path = paths.magnifier
            sizes = { default: { x: 20, y: 18 }, small: { x: 13, y: 12 } }
            break
        case 'list':
            path = paths.list
            sizes = { default: { x: 19, y: 12 } }
            break
        case 'menu':
            path = paths.menu
            sizes = { default: { x: 16, y: 12 } }
            break
        case 'checkmark':
            path = paths.checkmark
            sizes = { default: { x: 14, y: 11 } }
            break
        case 'exclamationmark':
            path = paths.exclamationmark
            sizes = { default: { x: 3, y: 12 } }
            break
        case 'move':
            path = paths.move
            sizes = { default: { x: 32, y: 33 }, small: { x: 24, y: 24 } }
            break
        case 'trash':
            path = paths.trash
            sizes = { default: { x: 14, y: 20 } }
            break
        case 'user':
            path = paths.user
            sizes = { default: { x: 32, y: 32 }, small: { x: 16, y: 16 } }
            break
        case 'download':
            path = paths.download
            sizes = { default: { x: 18, y: 19 } }
            break
        case 'pen':
            path = paths.pen
            sizes = { default: { x: 24, y: 32 }, small: { x: 9, y: 12 } }
            break
        case 'logo':
            path = paths.logo
            sizes = { default: { x: 345.5, y: 180 } }
            break
        case 'pin':
            path = paths.pin
            sizes = { default: { x: 32, y: 32 }, small: { x: 20, y: 20 } }
            break
        case 'upload-cloud':
            path = paths.uploadCloud
            sizes = { default: { x: 32, y: 32 } }
            break
        case 'lightning':
            path = paths.lightning
            sizes = { default: { x: 15, y: 20 } }
            break
        case 'email':
            path = paths.email
            sizes = { default: { x: 18, y: 17 } }
            break
        case 'info':
            path = paths.info
            sizes = { default: { x: 10, y: 20 } }
            break
        case 'preview':
            path = paths.preview
            sizes = { default: { x: 21, y: 18 } }
            break
        case 'envelope':
            path = paths.envelope
            sizes = { default: { x: 18, y: 17 } }
            break
        default:
    }

    if (!path) return null

    const proportions = size && sizes && sizes.hasOwnProperty(size) ? sizes[size] : sizes.default

    return path ? (
        <svg
            version="1.1"
            baseProfile="full"
            width={`${proportions.x}px`}
            height={`${proportions.y}px`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${sizes.default.x} ${sizes.default.y}`}
            transform={transform}
            x="0px"
            y="0px"
            className={className}
        >
            <g>
                {Array.isArray(path) ? (
                    path.map((p, i) => {
                        return <path d={p} key={`path_${i}`} />
                    })
                ) : (
                    <path d={path} />
                )}
            </g>
        </svg>
    ) : null
}

Svg.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    className: PropTypes.string,
}

export default Svg
