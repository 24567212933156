import Config from '../config/Config'

const timeout = (time, path, promise) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('Request timed out. Rest path: ' + path))
        }, time)
        promise.then(resolve, reject)
    })

const checkCredentials = () => {
    return new Promise((resolve, reject) => {
        const headers = new Headers()
        headers.append('Accept', 'application/json')
        headers.append('Content-Type', 'application/json')

        const url = `${Config.api.scheme}://${Config.api.domain}${Config.api.path}settings`

        const request = new Request(url, {
            headers,
            method: 'GET',
            credentials: 'include',
        })

        fetch(request).then(res => {
            if (res.status !== 'success') {
                reject('Unauthorized')
            } else {
                resolve('Authorized')
            }
        })
    })
}

const installationFromUrl = () => {
    let params = window.location.pathname.split('/'),
        pdx = ''

    if ((params[0] === '' || params[0].includes('pdx.fi')) && params[1] && params[1].trim().length > 0) {
        pdx = params[1].trim()
    }

    return pdx
}

const pdxLoginRedirect = (returnUrl = true) => {
    const pdx = installationFromUrl()

    if (pdx.length === 0) {
        document.location = 'https://www.pdx.fi'
        return {}
    } else {
        let url = `https://intra.pdx.fi/${pdx}/intra/index.php`
        if (returnUrl) {
            url += '?return_url=' + encodeURIComponent(window.location.href)
        }
        document.location = url
        return {}
    }
}

const fetchRest = async ({ path, payload, method = 'GET', params, defaults }) => {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')

    defaults = Object.assign({}, { extended: 1, remove_empty: 1 }, defaults)

    let urlParams = '?'
    if (params && params.length) {
        urlParams += params.join('&')
    }
    for (let key in defaults) {
        urlParams += `&${key}=${defaults[key]}`
    }
    let url = `${Config.api.scheme}://${Config.api.domain}${Config.api.path}${path}${urlParams}`

    const request = new Request(url, {
        headers,
        body: JSON.stringify(payload),
        method,
        credentials: 'include',
    })
    return await timeout(Config.api.timeout, path, fetch(request))
        .then(res => {
            if (res.status && res.status === 401) {
                return checkCredentials()
                    .then(isAuth => {
                        if (isAuth === 'Authorized') {
                            return res.json()
                        } else {
                            return pdxLoginRedirect()
                        }
                    })
                    .catch(() => {
                        return pdxLoginRedirect()
                    })
            } else {
                return res.json()
            }
        })
        .catch(err => console.log(err))
}

const queryVipunen = async ({ path, payload, method = 'GET', params }) => {
    let headers = new Headers()
    headers.append('Accept', '*/*')

    let urlParams = ''
    if (params && params.length) {
        urlParams += '?'
        urlParams += params.join('&')
    }

    let url = `${Config.vipunen.scheme}://${Config.vipunen.domain}/${path}${urlParams}`

    const body = new FormData()
    body.append('address', payload.address)
    const request = new Request(url, {
        headers,
        body,
        method,
    })

    return await timeout(Config.api.timeout, path, fetch(request))
        .then(res => {
            return res.json()
        })
        .catch(err => console.error(err))
}

const sendRest = async ({ path, payload, method = 'POST', formdata, contentType }) => {
    let headers = new Headers()
    headers.append('Accept', '*/*')

    const url = `${Config.api.scheme}://${Config.api.domain}${Config.api.path}${path}`

    let body
    if (formdata) {
        body = formdata
        if (contentType) headers.append('Content-Type', contentType)
    } else {
        headers.append('Content-Type', contentType || 'application/json')
        body = JSON.stringify(payload)
    }
    const request = new Request(url, {
        headers,
        body: body,
        method,
        credentials: 'include',
    })

    return await timeout(Config.api.timeout, path, fetch(request)).then(res => res.json())
}

const fetchKeyValuePairs = ({ storagePath, identifier }) => {
    return fetchRest({
        path: 'storage',
        params: [`path=${storagePath}`, `identifier=${identifier}`],
        defaults: {
            remove_empty: 0,
        },
    })
}

const storeKeyValuePairs = ({ data, path, identifier }) => {
    return fetchRest({
        path: 'storage',
        payload: { data, path, identifier },
        method: 'POST',
        defaults: { remove_empty: 0 },
    }).catch(err => console.error(err))
}

export {
    fetchRest,
    queryVipunen,
    sendRest,
    pdxLoginRedirect,
    installationFromUrl,
    fetchKeyValuePairs,
    storeKeyValuePairs,
}
