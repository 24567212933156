import Config from '../config/Config'

export const DecodeEntities = input => {
    if (typeof input !== 'string') return input

    const convTable = {
        '&euro;': '€',
        '¤': '€',
        'm&sup2;': 'm²',
    }

    let output = input

    for (const key in convTable) {
        const value = convTable[key]

        if (input && typeof input === 'string' && input.includes(key)) {
            output = output.replace(new RegExp(key, 'g'), value)
        }
    }

    return output || input
}

export const FormatNumber = ({
    input,
    locale = 'fi',
    decimals = 1,
    empty = true,
    trailingZero = false,
    thinSpace = true,
    separators = true,
    decode = false,
}) => {
    input = (input + '').replace(/,/g, '.')

    let parts = input.split('.')
    if (parts.length > 1) {
        input = parts.slice(0, -1).join('') + '.' + parts.slice(-1)
    }

    let num = parseFloat(input)

    if (isNaN(num)) return ''

    num = num.toFixed(decimals)

    if (!trailingZero) {
        const fractions = num.substring(num.indexOf('.'))
        if (!parseFloat(fractions)) {
            num = num.split('.')[0]
        } else {
            num = parseFloat(num).toString()
        }
    }

    const integer = num.split('.')[0]
    for (let space of [3, 6, 9, 12]) {
        if (integer.length > space) {
            const pos = integer.length - space
            let thousandSeparator
            if (separators) {
                switch (locale) {
                    case 'en':
                        thousandSeparator = ','
                        break
                    default:
                        thousandSeparator = thinSpace ? Config.chars.nbts : ' ' // NON-BREAKING THIN SPACE!
                }
            }
            num = [num.slice(0, pos), thousandSeparator, num.slice(pos)].join('')
        }
    }

    let decimalChar
    switch (locale) {
        case 'en':
            decimalChar = '.'
            break
        default:
            decimalChar = ','
    }
    num = num.replace('.', decimalChar)
    if (decode) num = num.replace(',', '.')

    return !empty && !parseFloat(num) ? '' : num
}

export const DecodeNumber = input =>
    parseFloat(
        String(input)
            .replace(Config.chars.nbts, '')
            .replace(' ', '')
            .replace(',', '.')
    )

export const AbbreviateAddress = address => {
    return address.replace(/\d+$/, '').trim()
}

export const hexToLuma = color => {
    if (!color) return
    let hex = color.replace(/#/, '')
    if (hex.length === 3) hex = hex + hex
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)

    return [0.299 * r, 0.587 * g, 0.114 * b].reduce((a, b) => a + b) / 255
}

export const hexToRGB = hex => {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}
