import styled, { css, keyframes } from 'styled-components'
import { fadeInOverlay } from './Animations'

export const Colors = {
    brandBlack: '#202020',
    brandWhite: '#FFFFFF',
    brandBlue: '#4498D3',
    actionBlue: '#1CA2E5',
    bgGray: '#f5f4f0',
    bgGrayLight: '#DDDDDD',
    uiGray: '#ccc',
    success: '#58da8c',
    error: '#D11515',
    brandBlueLight: '#16AAF1',
    brandBlueLighter: '#77cbec',
    brandBlueLightest: '#d5f0fb',
    brandOrange: '#f19c2d',
    darkGray: '#666',
    veryDarkGrey: '#9e9e9e',
    lightGray: '#ccc',
    greyRow: '#f1f4f7',
    errorRed: '#ce2f51',
    graphGreen: '#93E100',
    graphYellow: '#F9A900',
    graphRed: '#ff4949c7',
    darkTeal: '#24576b',
}

export const Fonts = {
    headingFont: '"Roboto Slab", Arial, sans-serif',
    brandFont: '"Open Sans", Arial, sans-serif',
    actionFont: '"Open Sans", Arial, sans-serif',
    familyBrochure: 'Tahoma',
    familyGUI: '"Trebuchet MS", Verdana, Arial, Helvetica',
    xtraSmall: '11px',
    small: '13px',
    smallish: '14px',
    action: '14px',
    mediumish: '15px',
    medium: '16px',
    large: '18px',
    heading: '22px',
    xtraLarge: '35px',
    linkHover: 'opacity: .7',
    linkActive: 'opacity: .4',
}

export const Headings = {
    blockHeading: css`
        font-family: ${Fonts.brandFont};
        font-size: ${Fonts.medium};
        color: ${Colors.brandWhite};
        background: ${Colors.brandBlack};
        padding: 0.7em 0.9em;
        display: block;
        border-radius: 3px 3px 0 0;
    `,
    modalHeading: css`
        font-family: ${Fonts.headingFont};
        font-size: ${Fonts.heading};
        font-weight: normal;
        text-align: center;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        padding: 0 2em;
    `,
}

const labelFont = css`
    font-size: ${Fonts.smallish};
    font-weight: 700;
    color: ${props => (props.disabled ? Colors.uiGray : Colors.brandBlack)};
`

export const UI = {
    gutter: '12px',
    contentWidth: 1200,
    headerHeight: 150,
    headerHeightMobile: 100,
    footerHeight: 240,
    zInputError: 800,
    zModal: 1000,
    zMenuOption: 1050,
    zMobileNavi: 1100,
    zShinkwrap: 1500,
    zConfirmation: 2000,
    labelFont: labelFont,
    labelStyles: css`
        ${labelFont};
        display: inherit;
        margin: 8px 0 0;
        padding-bottom: 6px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    `,
    linkStyles: css`
        &:hover {
            opacity: 0.7;

            &:disabled {
                opacity: 1;
                cursor: default;
            }
        }
        font-size: ${Fonts.smallish};
        font-weight: 700;
        color: ${Colors.brandBlue};
        cursor: pointer;
        user-select: none;
        background: transparent;
        border: 0;
    `,
    inputError: css`
        color: ${Colors.errorRed};
        font-size: ${Fonts.small};
        position: absolute;
        top: -60%;
        right: 0;
        padding: 0.4em 0.6em;
        background: ${Colors.brandWhite};
        border: 1px solid ${Colors.errorRed};
        border-radius: 3px;
        max-width: 80%;
        z-index: 800;
    `,
    inputShadow: css`
        box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.05);
    `,
    boxShadow: css`
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    `,
    togglers: css`
        input[type='radio'] {
            &:checked {
                & + label {
                    &:before {
                        content: '•';
                        font-size: 48px;
                        font-family: none;
                    }
                }
            }

            & + label {
                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: 0 auto auto;
                    content: '';
                    background-color: ${Colors.brandWhite};
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    overflow: hidden;
                    border: 1px solid ${Colors.uiGray};
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }

                position: relative;
                padding-right: 1rem;
                padding-left: 1.875rem;
                margin: 0 0 0.625rem 0;
                font-weight: normal;
                cursor: pointer;
                padding-top: 1px;
                font-size: 0.875rem;
            }

            &:disabled {
                & + label {
                    &:before {
                        opacity: 0.5;
                    }
                }
            }

            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        input[type='checkbox'] {
            &:checked {
                & + label {
                    &:before {
                        content: '✔';
                        font-family: none;
                    }
                }
            }

            & + label {
                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    content: '';
                    background-color: ${Colors.brandWhite};
                    border-radius: 3px;
                    width: 20px;
                    height: 20px;
                    overflow: hidden;
                    border: 1px solid ${Colors.uiGray};
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }

                position: relative;
                padding-right: 1rem;
                padding-left: 1.875rem;
                margin: 0 0 0.625rem 0;
                font-weight: normal;
                cursor: pointer;
                padding-top: 1px;
                font-size: 0.875rem;
            }

            &:disabled {
                & + label {
                    &:before {
                        opacity: 0.5;
                    }
                }
            }

            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        input + label {
            margin-right: 0.5em;
            user-select: none;
        }
    `,
    toastWrapper: css`
        min-height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;

        li {
            word-break: break-word;
        }
    `,
}

export const ToastWrapper = styled.div`
    ${UI.toastWrapper};
`

export const HelpIcon = styled.div`
    &:hover {
        cursor: help;
    }

    &:after {
        content: '?';
    }

    font-size: ${Fonts.small};
    font-weight: bold;
    background-color: ${Colors.brandBlack};
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.brandWhite};
    width: 17px;
    height: 17px;
    margin-left: 0.5em;
`

export const Animations = {
    dipIn: keyframes`
        0% {
            opacity: 0;
        }
        5% {
            opacity: 1;
        }
        95% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    `,
}

export const LoadingWrapper = styled.div`
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    & > * {
        pointer-events: ${props => (props.active ? 'none' : 'all')};
    }

    &.active {
        background-color: ${props => (props.active ? Colors.brandWhite : 'none')};
        animation: ${fadeInOverlay} 200ms;
        opacity: 0.3;
    }

    position: relative;
    pointer-events: none;
`

export const TruncText = css`
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
